import React, { useEffect, useState } from "react";
import {
  makeStyles,
  FormControl,
  MenuItem,
  Select,
  styled,
  Box,
} from "@material-ui/core";
import { getMonthName } from "./CalenderSelectField.web";
import { chevron_down } from "../assets";

const FieldGroupStyled = styled(Box)({
  cursor: "pointer"
});

const SelectDropdownStyled = styled(Select)({
  height: "48px",
  paddingBottom: "5px!important;",
  "& *": {
    background: "#fff !important;",
    fontWeight: 700,
    fontFamily: "Lato",
    fontSize: "12px !important",
    lineHeight: "19.2px",
    color:"#34373A",
    fontStyle:"normal",
  },
  '&:focus': {
    borderColor: '#cccccc',
  },
});

const DropdownFormControl = styled(FormControl)({
});

const MenuItemStyled = styled(MenuItem)({
  whiteSpace: 'normal',
  maxWidth: "100%",
});

const useStyles = makeStyles((theme) => ({
  textFieldStyle: {
    '& *': {
      borderRadius: "8px !important",
      borderColor: "#D9D9D9 !important",
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: "12px",
    },
    "& .MuiOutlinedInput-input": {
      marginLeft: "12px",
    },
    width: "100%",
    height: "32px",
  },
  textStyle: {
    lineHeight: "24px",
    fontWeight: 400,
    fontSize: "15px",
    fontFamily: "Lato",
    borderRadius: "8px",
    color: "#34373A",
    height: "48px",
    boxSizing: "border-box",
    padding: "0 15px",
  },
  muiSelectSelect: {
    '& *': {
      // fontSize: "15px !important"
    }
  },
}));

interface MonthYearSelectPropsType {
  onChange: (event: React.ChangeEvent<{value: unknown}>) => void;
  value: string | number;
  placeholder?: string;
  defaultSelectLabel?: string;
  fullwidth?: string;
  dynamicOptions?: string[];
}

export const getMonthYear = (): string[] => {
 
  const currentYear: number = new Date().getFullYear();
  const currentMonth: number = new Date().getMonth();

  // Set the start year and month
  let startYear = 2023;
  let startMonth = 0; // January

  const result: string[] = [];

  // Calculate the total number of months to iterate
  const totalMonths = (currentYear - startYear) * 12 + currentMonth;

  for (let i = 0; i < totalMonths; i++) {
    const yearOffset = Math.floor((startMonth + i) / 12);
    const year = startYear + yearOffset;
    const month = getMonthName((startMonth + i) % 12).month;
    result.push(`${month}-${year}`);
  }

  return result;
};

export const MonthYearSelect: React.FC<MonthYearSelectPropsType> = ({
  onChange,
  value, 
  placeholder,
  fullwidth,
  dynamicOptions,
}) => {
  const classes = useStyles();

  const [selectOpen, setSelectOpen] = useState(false)

  const handleOnOpen = () => {
    setSelectOpen(true);
  }

  const handleClose = () => {
    setSelectOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => selectOpen && handleClose();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [selectOpen]);

  const ddwidth = fullwidth ?? "130px";

  const options = dynamicOptions ?? getMonthYear();

  const DropdownIcon = (props: any) => {
    return <img {...props} alt="" style={{marginTop:"-1px",width:"20px",height:"20px"}} src={chevron_down} />
  };

  return (
    <FieldGroupStyled style={{ minWidth: ddwidth }}>
      <DropdownFormControl>
        <SelectDropdownStyled
          displayEmpty
          data-test-id="month_year_test"
          onOpen={handleOnOpen}
          onClose={handleClose}
          onScroll={handleClose}
          onWheel={handleClose}
          open={selectOpen}
          placeholder={placeholder}
          classes={{ root: classes.muiSelectSelect }}
          value={value}
          IconComponent={DropdownIcon}
          onChange={onChange}
          disableUnderline
          MenuProps={{
            PaperProps: {
              style: {
                width: 'min-content',
                maxHeight: 200,
                top:"195px",
                left:"1365px"
              },
            },
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom',
            },
            transformOrigin: {
              horizontal: 'left',
              vertical: 'top',
            },
            getContentAnchorEl: null,
          }}
        >
          {options && options.map((option: any) => (
            <MenuItemStyled
              key={`${option.toLowerCase()}_month_year`}
              value={option}
            >
              <span
                style={{ fontSize: "15px",paddingRight:"13px" }}
              > {option}
              </span>

            </MenuItemStyled>
          ))}
        </SelectDropdownStyled>
      </DropdownFormControl>
    </FieldGroupStyled>
  );
};

export default MonthYearSelect;
