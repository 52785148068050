import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
    Box,
    Container,
    Typography,
    Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import parse from 'html-react-parser';
import EmailNotifications2 from "../../EmailNotifications2/src/EmailNotifications2.web";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        darkTheme: {
            backgroundColor: "#34373A",
            width: "100%",
        },
        boxContainer: {
            padding: "72px 0",
            '@media(max-width: 600px)': {
                padding: "30px 0",
            },
        },
        buttonStyle: {
            padding: "16px 12px",
            width: "252px",
            background: '#43D270',
            color: '#101010',
            borderRadius: "8px",
            margin: "0 15px 15px 15px",
            boxShadow: "none !important",
            alignItems: "center",
            '@media(max-width: 600px)': {
                width: "160px",
                margin: "0 10px 10px 10px",
                padding: "14px 10px",
            },
            '@media(max-width: 400px)': {
                margin: "0 5px 5px 5px",
                width: "140px",
                padding: "14px",
                fontSize: "11px",
            },
        },
        buttonIconStyle: {
            marginLeft: '8px',
            transform: "rotate(-45deg)"
        },
        buttonLinkStyle: {
            textDecoration: "unset",
        },
        lightTheme: {
            backgroundColor: "#ffffff",
            width: "100%",
        },

        ctaWrapper: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "10px",
            "@media(max-width: 1024px)": {
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
        },

        darkThemeHeading: {
            fontFamily: "Montserrat",
            fontSize: "38px",
            fontWeight: 700,
            lineHeight: "53.2px",
            color: "#ffffff",
            "& *": {
                fontFamily: "Montserrat",
                fontSize: "38px",
                fontWeight: 700,
                lineHeight: "53.2px",
                color: "#ffffff",
                "& b": {
                    color: "#43D270",
                    display: "inline-block",
                },
                "& span": {
                    color: "#43D270",
                    display: "inline-block",
                },
                "& strong": {
                    color: "#43D270",
                },
            },
            "& b": {
                color: "#43D270",
                display: "inline-block",
            },
            "& span": {
                color: "#43D270",
                display: "inline-block",
            },
            "& strong": {
                color: "#43D270",
            },
            "@media(max-width: 600px)": {
                fontSize: "28px",
                lineHeight: "30px",
                marginBottom: "30px",
            },
        },

        darkThemeHeadingSm: {
            fontSize: "24px",
            fontWeight: 400,
            fontFamily: "Montserrat",
            lineHeight: "38px",
            color: "#ffffff",
            textAlign: "center",
            flexDirection: "column",
            paddingBottom: "24px",
            maxWidth: "800px",
            "& *": {
                fontWeight: 400,
                fontFamily: "Montserrat",
                "& span": {
                    color: "#43D270",
                    display: "inline-block",
                },
                "& b": {
                    color: "#43D270",
                    display: "inline-block",
                },
                "& strong": {
                    color: "#43D270",
                },
            },
            "& b": {
                color: "#43D270",
                display: "inline-block",
            },
            "& strong": {
                color: "#43D270",
            },
            "@media(max-width: 600px)": {
                fontSize: "30px",
                lineHeight: "1.4",
                marginBottom: "30px",
            },
        },

        lightThemeHeading: {
            fontSize: "48px",
            fontWeight: 700,
            fontFamily: "Montserrat",
            lineHeight: "56px",
            margin: "0 auto 60px auto",
            color: "#34373A",
            textAlign: "center",
            maxWidth: "800px",
            "& *": {
                fontWeight: 700,
                fontFamily: "Montserrat",
                "& span": {
                    color: "#43D270",
                    display: "inline-block",
                },
                "& strong": {
                    color: "#43D270",
                },
                "& b": {
                    color: "#43D270",
                    display: "inline-block",
                },
            },
            "& b": {
                color: "#43D270",
                display: "inline-block",
            },
            "& strong": {
                color: "#43D270",
            },
            "@media(max-width: 600px)": {
                fontSize: "30px",
                lineHeight: "1.4",
                marginBottom: "30px",
            },
        },

        lightThemeHeadingSm: {
            fontSize: "24px",
            fontWeight: 400,
            fontFamily: "Montserrat",
            lineHeight: "38px",
            color: "#34373A",
            flexDirection: "column",
            margin: "0 auto",
            paddingBottom: "24px",
            maxWidth: "724px",
            "& *": {
                fontWeight: 700,
                fontFamily: "Montserrat",
                "& b": {
                    color: "#43D270",
                    display: "inline-block",
                },
                "& span": {
                    color: "#43D270",
                    display: "inline-block",
                },
                "& strong": {
                    color: "#43D270",
                },
            },
            "& b": {
                color: "#43D270",
                display: "inline-block",
            },
            "& strong": {
                color: "#43D270",
            },
            "@media(max-width: 600px)": {
                fontSize: "30px",
                lineHeight: "1.4",
                marginBottom: "30px",
            },
            "@media(max-width: 450px)": {
                marginBottom: "0px",
            },
        },

        ctaBox: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },

        ctaInput: {
            backgroundColor: "#F1F1F5",
            width: "414px",
            maxWidth: "100%",
            fontSize: "15px",
            height: "50px",
            borderRadius: "8px",
            overflow: "hidden",
            "& .MuiInputBase-root": {
                borderRadius: "8px !important",
                backgroundColor: "#FAFAFB",
                border: "1px solid #f1f1f5 !important"
            },
            "& .MuiOutlinedInput-input": {
                padding: "15px",
            },
            '& input ': {
                height: "50px",
                boxSizing: "border-box",
                padding: "13px 20px",
                fontSize: "15px"
            },
            "@media(max-width: 450px)": {
                width: "300px",
                maxWidth: "100%",
            },
        },
        linkStyle2: {
            color: '#101010',
            display: 'flex',
            textDecoration: 'none',
        },


        ctaButton: {
            backgroundColor: "#43D270",
            fontSize: "15px",
            fontWeight: 500,
            fontFamily: "Lato",
            lineHeight: "18px",
            minHeight: "50px",
            width: "178px",
            borderRadius: "8px",
            boxShadow: "none !important",
            "@media(max-width: 670px)": {
                marginBottom: "30px",
            },
        },

        ctaThankYouBox: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        },

        darkThumbIconStyle: {
            opacity: 1,
        },

        lightThumbIconStyle: {
            filter: "invert(1)",
            opacity: "0.6",
        },
        emailContainer: {
            justifyContent: "flex-end",
            "@media(max-width: 960px)": {
                justifyContent: "flex-start"
            }
        }
    })
);

interface ThemeProps {
    heading?: string | React.ReactNode;
    thankYouMessage?: string;
    buttonLabel?: string;
    themeMode?: "light" | "dark";
    svgpath?: any;
    inputPlaceholder?: string;
    inputValue?: string;
    headingType?: "sm" | "lg";
    submitted?: boolean;
    buttonLink?: any;
    onPrivacyPolicyClick: () => void;
}

const CallToAction = ({
    heading,
    thankYouMessage = "Thank you for subscribing. You’ll get a welcome mail shortly.",
    buttonLabel,
    inputPlaceholder,
    themeMode,
    inputValue,
    headingType,
    buttonLink,
    onPrivacyPolicyClick,
}: ThemeProps) => {
    const classes = useStyles();
    const [submitted, setSubmitted] = useState<boolean>(false)
    const backgroundMode =
        themeMode === "dark" ? classes.darkTheme : classes.lightTheme;
    const ctaHeadingStyle =
        themeMode === "dark" ? classes.darkThemeHeading : classes.lightThemeHeading;
    const ctaHeadingStyle2 =
        themeMode === "dark"
            ? classes.darkThemeHeadingSm
            : classes.lightThemeHeadingSm;
    const thumbIconStyle =
        themeMode === "dark"
            ? classes.darkThumbIconStyle
            : classes.lightThumbIconStyle;

    const defaultHeading = "Try Our <b style={{color:'#43D270'}}>‍Free</b> 14-Day Trial Today & Decide Later!";

    return (
        <Box className={`${backgroundMode} ${classes.boxContainer}`}>
            <Container>
                {!submitted ? (
                    <Grid container spacing={2} data-test-id={'CtaBox'}>
                        <Grid item md={6} xs={12}>
                            <Typography
                                className={
                                    headingType === "lg" ? ctaHeadingStyle : ctaHeadingStyle2
                                }
                            > {heading ? parse(heading.toString().replace('!!', "!")) : parse(defaultHeading.toString())}
                            </Typography>
                        </Grid>
                        <Grid 
                            item 
                            container 
                            md={6} 
                            xs={12}
                            className={classes.emailContainer}
                        >
                            <EmailNotifications2 
                                navigation={undefined} 
                                id={""} 
                                placeholder={inputPlaceholder} 
                                buttonLabel={buttonLabel} 
                                setSubmitted={setSubmitted} 
                                onPrivacyPolicyClick={onPrivacyPolicyClick}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Box className={classes.ctaThankYouBox}>
                        <Typography className={ctaHeadingStyle2}>
                            {thankYouMessage}
                        </Typography>
                        <svg
                            className={thumbIconStyle}
                            width="41"
                            height="38"
                            viewBox="0 0 41 38"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M32 11H36C38.2092 11 40 12.7909 40 15V33C40 35.2091 38.2092 37 36 37H32C29.7909 37 28 35.2091 28 33V15C28 12.7909 29.7909 11 32 11Z"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M12.2462 37H19.5778C21.1572 37 22.7013 36.5325 24.0154 35.6564L27.1094 33.5937C27.6658 33.2228 28 32.5983 28 31.9296V15.5311C28 15.1831 27.9092 14.841 27.7365 14.5389L20 1H17.3426C13.3491 1 10.9672 5.45072 13.1824 8.7735L16 13H5.12313C2.52084 13 0.611408 15.4456 1.24256 17.9701L4.48509 30.9403C5.37543 34.5016 8.57529 37 12.2462 37Z"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </Box>
                )}
            </Container>
        </Box>
    );
};

const CallToActionSection = (props: any) => {
    return <CallToAction {...props} onPrivacyPolicyClick={props.onPrivacyPolicyClick} />;
};

export default CallToActionSection;
