import React from "react";
// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import ReportBuilderController, {
    Props,
    configJSON,
} from "./ReportBuilderController";

import Layout from "../../../dashboard/src/Layout.web";
import { Box, Button, Typography } from "@material-ui/core";
import { reportIcon } from "../assets";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

// Customizable Area End



// Customizable Area Start
export default class ReportBuilder extends ReportBuilderController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Layout>
                    <Box style={webStyle.headerContainer}>
                        <Typography style={webStyle.titleText}>{configJSON.reportBuilderText}</Typography>
                        <Typography style={webStyle.descriptionText}>{configJSON.reportBuilderDescription}</Typography>
                    </Box>
                    <hr color="#EBEBEB" />
                    <Box style={webStyle.innerContainer}>
                        <Typography style={webStyle.sampleReportText}>{configJSON.viewSampleReports}</Typography>
                        <Typography style={webStyle.sampleDescriptionText}>{configJSON.sampleReportsDscription}</Typography>

                        <Box style={webStyle.rowContainer}>
                            <Box style={webStyle.sampleCard}>
                                <img src={reportIcon} style={webStyle.sampleCardImg} />
                                <Box style={{display: 'flex', flexDirection: 'column'}}>
                                <Typography style={webStyle.sampleCardLabel}>Sample 1</Typography>
                                <Typography style={webStyle.sampleCardTitle}>Lorem Ipsum</Typography>
                                </Box>
                            </Box>

                            <Box style={webStyle.sampleCard}>
                                <img src={reportIcon} style={webStyle.sampleCardImg} />
                                <Box style={{display: 'flex', flexDirection: 'column'}}>
                                <Typography style={webStyle.sampleCardLabel}>Sample 2</Typography>
                                <Typography style={webStyle.sampleCardTitle}>Lorem Ipsum</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <hr color="#EBEBEB" />
                    <Box style={webStyle.innerContainer}>
                        <Typography style={webStyle.sampleReportText}>{configJSON.createOwnReport}</Typography>
                        <Typography style={webStyle.sampleDescriptionText}>{configJSON.createOwnReportDescription}</Typography>

                        <Button
                            data-test-id="report-builder-form-btn"
                            variant="contained"
                            style={webStyle.buttonContainer}
                            onClick={this.navigateToReportBuilderForm}
                        >
                            <Typography style={webStyle.buttonText}>Report Builder Form</Typography>
                        </Button>
                    </Box>
                </Layout>
            </ThemeProvider>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const webStyle = {
    headerContainer: {
        marginTop: '32px',
        padding: "0 44px 30px",
    },
    titleText:{
        fontSize: '34px',
        lineHeight: '46px',
        fontWeight: 700,
        fontFamily: 'Montserrat',
        color: '#34373A',
        marginBottom: '10px',
    },
    descriptionText:{
        fontSize: '16px',
        lineHeight: '26px',
        fontWeight: 400,
        fontFamily: 'Lato',
        color: '#34373A',
    },
    innerContainer:{
        padding: "40px 44px",
    },
    sampleReportText:{
        fontSize: '20px',
        lineHeight: '25px',
        fontWeight: 600,
        fontFamily: 'Montserrat',
        color: '#34373A',
        marginBottom: '6px'
    },
    sampleDescriptionText:{
        fontSize: '12px',
        lineHeight: '26px',
        fontWeight: 400,
        fontFamily: 'Lato',
        color: '#7D7D7F',
    },
    rowContainer:{
        marginTop: '27px',
        display: 'flex',
        alignItems: 'center',
        gap: '24px'
    },
    sampleCard:{
        width: '264px',
        height: '104px',
        border: '1px solid #EBEBEB',
        borderRadius: '12px',
        padding: '28px 24px',
        display: 'flex',
        alignItems: 'center',
    },
    sampleCardImg:{
        width: '48px',
        height: '48px',
        marginRight: '16px',
    },
    sampleCardLabel:{
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 500,
        fontFamily: 'Lato',
        color: '#7D7D7F',
        marginBottom: '4px'
    },
    sampleCardTitle:{
        fontSize: '16px',
        lineHeight: '22.4px',
        fontWeight: 600,
        fontFamily: 'Lato',
        color: '#34373A',
    },
    buttonText:{
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 700,
        fontFamily: 'Lato',
        color: '#34373A',
        cursor:'pointer'
    },
    buttonContainer:{
        background: "#43D270",
        padding: '8px 10px',
        borderRadius: '8px',
        textTransform:'capitalize',
        marginTop: '20px',
    } as React.CSSProperties,
};
// Customizable Area End
