import React, { CSSProperties } from "react";

// Customizable Area Start
import {
    TextField,
    Button,
    Grid,
    InputLabel,
    // Customizable Area Start
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    FormControl,
    CircularProgress
    // Customizable Area End
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { ArrowUpward } from "@material-ui/icons";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff"
        }
    },
    typography: {
        h6: {
            fontWeight: 500
        },
        subtitle1: {
            margin: "20px 0px"
        }
    },
    overrides: {
        MuiSelect: {
            select: {
                "&:focus": {
                    backgroundColor: "transparent",
                },
                "&:hover": {
                    border: 'none'
                }
            }
        }
    }
});
import { Link } from 'react-router-dom';


import { checkedIcon, uncheckedIcon } from "./assets";
const configJSON = require("./config");
// Customizable Area End

import EmailAccountRegistrationController, {
    Props
} from "./EmailAccountRegistrationController";
import { Formik } from "formik";
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        this.getValidations();
        this.checkLogin()
        // Customizable Area End
    }
    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {this.props.activeStep === 0 && (
                    <Formik
                        initialValues={this.state.initialValue1}
                        validationSchema={this.state.signupSchema1}
                        onSubmit={values => this.handleNext(values)}
                    >
                        {formik => {
                            const {
                                values,
                                touched,
                                handleChange,
                                handleSubmit,
                                errors,
                                handleBlur,
                            } = formik;

                            return (
                                <form
                                    onSubmit={handleSubmit}
                                    className="form-one"
                                    data-test-id="accountLogin"
                                >
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <InputLabel htmlFor="email" style={webStyle.labelInput}>
                                                Work Email
                                            </InputLabel>
                                            <TextField
                                                id="email"
                                                name="email"
                                                placeholder="Enter your company email address here"
                                                fullWidth
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                                margin="normal"
                                                onChange={handleChange}
                                                value={values.email}
                                                style={{
                                                    border: touched.email && errors.email ? "#FFA39E solid 1px" : "#D9D9D9 solid 1px",
                                                    borderRadius: "8px",
                                                    padding: "8.5px 14px",
                                                    font: "inherit",
                                                    height: "48px",
                                                    maxWidth: '404px',
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            {touched.email && errors.email ? (
                                                <div style={webStyle.red}>{errors.email}</div>
                                            ) : null}
                                            {this.state.localErrors?.email && (
                                                <div style={webStyle.red}>{this.state.localErrors.email}</div>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel htmlFor="fname" style={webStyle.labelInput}>
                                                First Name
                                            </InputLabel>
                                            <TextField
                                                id="fname"
                                                name="fname"
                                                placeholder="Enter your first name here"
                                                fullWidth
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                                margin="normal"
                                                onChange={handleChange}
                                                value={values.fname}
                                                style={{
                                                    border: touched.fname && errors.fname ? "#FFA39E solid 1px" : "#D9D9D9 solid 1px",
                                                    borderRadius: "8px",
                                                    padding: "8.5px 14px",
                                                    font: "inherit",
                                                    height: "48px",
                                                    maxWidth: '404px'
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            {touched.fname && errors.fname ? (
                                                <div style={webStyle.red}>{errors.fname}</div>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel htmlFor="lname" style={webStyle.labelInput}>
                                                Last Name
                                            </InputLabel>
                                            <TextField
                                                id="lname"
                                                name="lname"
                                                placeholder="Enter your last name here"
                                                fullWidth
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                                margin="normal"
                                                onChange={handleChange}
                                                value={values.lname}
                                                style={{
                                                    border: touched.lname && errors.lname ? "#FFA39E solid 1px" : "#D9D9D9 solid 1px",
                                                    borderRadius: "8px",
                                                    padding: "8.5px 14px",
                                                    font: "inherit",
                                                    height: "48px",
                                                    maxWidth: '404px',
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            {touched.lname && errors.lname ? (
                                                <div style={webStyle.red}>{errors.lname}</div>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel htmlFor="company" style={webStyle.labelInput}>
                                                Company Name
                                            </InputLabel>
                                            <TextField
                                                id="company"
                                                name="company"
                                                data-testId="company"
                                                placeholder="Enter your company name here"
                                                fullWidth
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                                margin="normal"
                                                onChange={handleChange}
                                                value={values.company}
                                                style={{
                                                    border: touched.company && errors.company ? "#FFA39E solid 1px" : "#D9D9D9 solid 1px",
                                                    borderRadius: "8px",
                                                    padding: "8.5px 14px",
                                                    font: "inherit",
                                                    height: "48px",
                                                    maxWidth: '404px'
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            {touched.company && errors.company ? (
                                                <div style={{...webStyle.red,maxWidth:'404px'}}>{errors.company}</div>
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                    <div style={webStyle.ButtonStyle}>
                                        <Button
                                            data-test-id="submitForm"
                                            variant="contained"
                                            style={{
                                                backgroundColor: this.isGoNext(errors, values),
                                                borderRadius: "8px",
                                                width: "184px",
                                                height: "56px",
                                                color: "#FFFFFF"
                                            }}
                                            type="submit"
                                        >
                                            {this.state.isRegistering ? <CircularProgress style={{width: '30px', height: '30px'}} /> : 'Next'}
                                        </Button>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>
                )}
                {this.props.activeStep === 1 && (
                    <Formik
                        initialValues={this.state.initialValue3}
                        validationSchema={this.state.signupSchema3}
                        onSubmit={values => this.handleNext(values)}
                    >
                        {formik => {
                            const {
                                values,
                                touched,
                                handleChange,
                                handleSubmit,
                                errors,
                                handleBlur,
                            } = formik;
                            return (
                                <form
                                    onSubmit={handleSubmit}
                                    className="form-one"
                                    data-test-id="accountLogin"
                                >
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <InputLabel
                                                htmlFor="username"
                                                style={webStyle.labelInput}
                                            >
                                                Username
                                            </InputLabel>
                                            <TextField
                                                id="username"
                                                name="username"
                                                placeholder="Enter a username of your choice"
                                                fullWidth
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                                margin="normal"
                                                onChange={handleChange}
                                                value={values.username}
                                                style={{
                                                    border: touched.username && errors.username ? "#FFA39E solid 1px" : "#D9D9D9 solid 1px",
                                                    borderRadius: "8px",
                                                    padding: "8.5px 14px",
                                                    font: "inherit",
                                                    height: "48px",
                                                    maxWidth: '404px'
                                                }}
                                                onBlur={(e) => {
                                                    this.handleSaveOnBlur(e);
                                                    handleBlur(e);
                                                }}                                            />
                                            {touched.username && errors.username ? (
                                                <div style={{ ...webStyle.red, maxWidth: "404px" }}>{errors.username}</div>
                                            ) : null}
                                            {this.state.localErrors?.user_name && (
                                                <div style={webStyle.red}>{this.state.localErrors.user_name}</div>
                                            )}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <InputLabel
                                                htmlFor="password"
                                                style={webStyle.labelInput}
                                            >
                                                Password
                                            </InputLabel>
                                            <TextField
                                                id="password"
                                                name="password"
                                                type="password"
                                                placeholder="Enter your password here"
                                                fullWidth
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                                margin="normal"
                                                onChange={handleChange}
                                                value={values.password}
                                                style={{
                                                    border: touched.password && errors.password ? "#FFA39E solid 1px" : "#D9D9D9 solid 1px",
                                                    borderRadius: "8px",
                                                    padding: "8.5px 14px",
                                                    font: "inherit",
                                                    height: "48px",
                                                    maxWidth: '404px'
                                                }}
                                                onBlur={(e) => {
                                                    this.handleSaveOnBlur(e);
                                                    handleBlur(e);
                                                }}                                            />
                                            {touched.password && errors.password ? (
                                                <div style={{ ...webStyle.red, width: '338px' }}>{errors.password}</div>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel
                                                htmlFor="confirmpassword"
                                                style={webStyle.labelInput}
                                            >
                                                Confirm Password
                                            </InputLabel>
                                            <TextField
                                                id="confirmpassword"
                                                name="confirmpassword"
                                                type="password"
                                                placeholder="Please confirm your password again"
                                                fullWidth
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                                margin="normal"
                                                onChange={handleChange}
                                                value={values.confirmpassword}
                                                style={{
                                                    border: touched.confirmpassword && errors.confirmpassword ? "#FFA39E solid 1px" : "#D9D9D9 solid 1px",
                                                    borderRadius: "8px",
                                                    padding: "8.5px 14px",
                                                    font: "inherit",
                                                    height: "48px",
                                                    maxWidth: '404px'
                                                }}
                                                onBlur={(e) => {
                                                    this.handleSaveOnBlur(e);
                                                    handleBlur(e);
                                                }}                                             />
                                            {touched.confirmpassword && errors.confirmpassword ? (
                                                <div style={webStyle.red}>{errors.confirmpassword}</div>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{ display: 'flex' }}>
                                                <FormControlLabel
                                                    id="policy"
                                                    name="acceptTerms"
                                                    label=""
                                                    control={
                                                        <Checkbox 
                                                            disableRipple
                                                            checkedIcon={<img src={checkedIcon} style={{ width: "20px", height: "20px" }} />}
                                                            icon={<img src={uncheckedIcon} style={{ width: "20px", height: "20px" }} />}
                                                        />
                                                    }
                                                    onBlur={(e) => {
                                                        this.handleSaveOnBlur(e);
                                                        handleBlur(e);
                                                    }} 
                                                    onChange={handleChange}
                                                />
                                                <p style={webStyle.policy as CSSProperties}>{configJSON.creatingAccount}
                                                    <Link target="_blank" style={webStyle.link} to="/terms-and-conditions"> Terms & Conditions </Link>
                                                    {configJSON.and}
                                                    <Link target="_blank" style={webStyle.link} to="/privacy-policy">{configJSON.privacyPolicy}</Link></p>
                                            </div>
                                            {touched.acceptTerms && errors.acceptTerms ? (
                                                <div style={webStyle.red}>{errors.acceptTerms}</div>
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "32px"
                                    }}>
                                        <Button
                                            data-test-id="submitForm"
                                            variant="contained"
                                            style={{
                                                backgroundColor: this.isGoNext(errors, values),
                                                borderRadius: "8px",
                                                width: "184px",
                                                height: "56px",
                                                color: "#FFFFFF",
                                                marginRight: '110px'
                                            }}
                                            type="submit"
                                            endIcon={this.state.isRegistering ? null : <ArrowUpward style={webStyle.icon} />}
                                        >
                                            {this.state.isRegistering ? <CircularProgress style={{width: '30px', height: '30px'}} /> : 'Sign up'}
                                        </Button>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>
                )}
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    containerStyle: {
        paddingTop: "75px"
    },
    form: {
        maxWidth: "500px",
        margin: "20px auto"
    },
    labelInput: {
        color: "#34373A",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "22px",
        display: "flex",
        alignItems: "center"
    },
    select: {
        borderColor: "#D9D9D9",
        borderRadius: "8px",
        color: '#a2a2a2',
        fontamily: "Lato",
        fontStyle: "normal"
    },
    red: {
        color: "#F5222D",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px"
    },
    ButtonStyle: {
        display: "flex",
        justifyContent: "center",
        marginTop: "60px",
        maxWidth: '404px',
        '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
            marginTop: "20px",
           }
    },
    button: {
        backgroundColor: "#B1B3C3",
        borderRadius: "8px",
        width: "184px",
        height: "56px",
        color: "#FFFFFF"
    },
    submitbtn: {
        backgroundColor: "#7166F1",
        borderRadius: "8px",
        width: "184px",
        height: "56px",
        color: "#FFFFFF"
    },
    policy: {
        color: "#64748B",
        fontFamily: 'Lato',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "160%",
        marginLeft: '-17px',
        marginTop: '18px',
        width: '369px'
    },
    icon: {
        rotate: "45deg",
        marginLeft: "5px"
    },
    link: {
        textDecoration: 'none',
        color: '#101010'
    }
};
// Customizable Area End
