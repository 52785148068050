import React from "react";

// Customizable Area Start
import { Box, IconButton, Modal, Paper,  Radio,  TextField,  Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { plan, plan_active, bill, bill_active, invoice, invoice_active } from "./assets";
import Plans from "./Plans.web";
import Invoices from "./Invoices.web";
import Billing from "./Billing.web";
import Layout from "../../dashboard/src/Layout.web";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import Autocomplete from "@material-ui/lab/Autocomplete";

const InnerContainer = styled("div")({
    width: "100%",
    height: "auto",
    padding: "27px 0 44px 0px"
});

const PlanTabsSection = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: "8px",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "32px",
    marginLeft: '44px',
    marginRight: '32px',
    '@media(max-width: 450px)': {
        alignItems: 'end'
    },
});

const PlanTabItem = styled("div")({
    display: "flex",
    justifyContent: "center",
    padding: "8px 12px",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
    borderRadius: "8px",
    backgroundColor: "#FFF",
    color: "grey",
    fontSize: "15px",
    height: "40px",
    cursor: "pointer",
});

const PlanTabItemSelected = styled("div")({
    display: "flex",
    justifyContent: "center",
    padding: "8px 12px",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
    borderRadius: "8px",
    backgroundColor: "#ECFBF1",
    color: "grey",
    fontSize: "15px",
    cursor: "pointer",
});

const PlanTabText = styled(Typography)({
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    letterSpacing: "0.2px",
    cursor: "pointer",
});

const PlanTabTextSelected = styled(PlanTabText)({
    color: "#329E54",
    fontWeight: 700,
});

const PlanIcon = styled("span")({
    width: "20px",
    height: "20px",
    marginTop: '-5px'
});

const PlanTabContent = styled('div')({
    width: "100%",
})

const PlanTabLink = styled(Link)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
})

const PlanTabs = styled('div')({
    maxWidth: '404px',
    display: 'flex',
    gap: '8px',
    '@media(max-width: 450px)': {
        flexDirection: 'column',
        alignItems: 'start'
    },
})


const OrgBaseUrl = "/settings/plan&billing";
// Customizable Area End

import CustomisableusersubscriptionsController, {
    Props,
} from "./CustomisableusersubscriptionsController";
import { checkbox, checked, close } from "../../customform/src/assets";
import { arrowDown } from "../../dashboard/src/assets";
import FileUploader from "../../../components/src/FilteUploader.web";
import { successCheck } from "../../GoalManagement/src/assets";

export default class PlanAndBilling extends CustomisableusersubscriptionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getPlans()
    }

    renderUploadInvoiceButton = (activeTab:string) => {
        if (activeTab === 'invoices' && this.account_type === "portfolio_manager") {
            return (
                <>
                    <CustomButton
                        data-test-id="add-invoice-btn"
                        onClick={() => {
                            this.setState({ openUploadInvoiceForm: true });
                        }}
                    >
                        <CustomTypography>Upload Invoice</CustomTypography>
                    </CustomButton>
                </>
            )
        }
        return null;
    }

    renderUploadInvoiceModal = () => {
        return (
            <Modal
                open={this.state.openUploadInvoiceForm}
                onClose={() => { this.setState({ openUploadInvoiceForm: false }) }}
                style={invoiceFormStyle.modal}
                data-test-id="upload-invoice-modal"
            >
                <Paper style={invoiceFormStyle.container}>
                    <Box style={invoiceFormStyle.headcontainer}>
                        <Typography style={invoiceFormStyle.title}>Upload Invoice</Typography>
                        <IconButton
                            data-test-id="close-icon"
                            style={{ padding: '5px' }}
                            onClick={() => {
                                this.setState({ openUploadInvoiceForm: false });
                            }}
                        >
                            <img src={close} alt="close" />
                        </IconButton>
                    </Box>

                    <Box style={{ marginTop: '10px', marginBottom: '30px' }}>
                        <InputLabel>Date</InputLabel>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                data-testid="start-date"
                                placeholder="DD-MM-YYYY"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                format="DD-MM-YYYY"
                                autoOk
                                openTo="date"
                                style={invoiceFormStyle.datetimeinput}
                                fullWidth
                                disablePast
                                value={this.state.invoiceDate}
                                onChange={(date: Date | null) => {
                                    this.setState({ invoiceDate: date });
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                minDateMessage=''
                                maxDateMessage=''
                            />
                        </MuiPickersUtilsProvider>
                    </Box>

                    <Box style={{ marginBottom: '30px' }}>
                        <InputLabel>Select Plan</InputLabel>
                        <Autocomplete
                            id="subscription-plan-dropdown"
                            options={this.state.subscriptions}
                            disableCloseOnSelect={false}
                            value={this.state.selectedSubscriptionPlan}
                            data-test-id="plan-dropdown"
                            onChange={(event, value) => {
                                this.setState({ selectedSubscriptionPlan: value, subscriptionPlanError: '' });
                            }}
                            closeIcon={false}
                            popupIcon={<div style={invoiceFormStyle.arrowDiv}>
                                <img src={arrowDown} />
                            </div>}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    name="memberlist"
                                    placeholder="Select one from the list"
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                        style: invoiceFormStyle.autoComplete
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        value: this.state.selectedSubscriptionPlan?.level ?? ''
                                    }}
                                />
                            }
                            renderOption={(option: any, { selected }: any) => {
                                return (
                                    <li style={{ maxHeight: "18px", display: 'flex', alignItems: 'center', padding: 0 }}>
                                        <Radio
                                            icon={<img src={checkbox} />}
                                            checkedIcon={<img src={checked} />}
                                            style={{ marginLeft: -16 }}
                                            checked={selected}
                                        />
                                        <Typography style={{ ...invoiceFormStyle.optionName, color: selected ? "#34373A" : "#7D7D7F", }}>{option.level}</Typography>
                                    </li>
                                );
                            }}
                            PopperComponent={({ children, ...other }) => (
                                <div {...other} style={invoiceFormStyle.modelSubpopper}>
                                    {children}
                                </div>
                            )}
                            getOptionLabel={(option: any) => option.level}
                        // disabled={this.checkIsDisabled()}
                        />
                        {this.state.subscriptionPlanError && (
                            <Typography style={{ fontSize: '12px', lineHeight: '19px', fontWeight: 400, color: '#F5222D', marginTop: '5px' }}>
                                {this.state.subscriptionPlanError}
                            </Typography>
                        )}
                    </Box>

                    <Box style={{ marginBottom: '30px' }}>
                        <InputLabel>Price</InputLabel>
                        <InputField
                            placeholder="Add price..."
                            variant="outlined"
                            InputProps={{
                                style: invoiceFormStyle.inputField,
                            }}
                            value={this.state.price}
                            onChange={this.handlePriceChange}
                            error={this.state.priceError ? true : false}
                            data-test-id="price-input"
                        />
                        {this.state.priceError && (
                            <Typography style={{ fontSize: '12px', lineHeight: '19px', fontWeight: 400, color: '#F5222D', marginTop: '5px' }}>
                                {this.state.priceError}
                            </Typography>
                        )}
                    </Box>

                    <Box style={{ marginBottom: '30px' }}>
                        <InputLabel>Select File</InputLabel>
                        <FileUploader
                            data-test-id="file-input"
                            onSelect={files => {
                                this.setState({ invoiceFiles: files, invoiceFilesError: '' });
                            }}
                        />
                        {this.state.invoiceFilesError && (
                            <Typography style={{ fontSize: '12px', lineHeight: '19px', fontWeight: 400, color: '#F5222D', }}>
                                {this.state.invoiceFilesError}
                            </Typography>
                        )}
                    </Box>

                    <Box style={{ marginBottom: '30px', display: 'flex', justifyContent: 'center' }}>
                        <Box
                            style={this.checkIsDisabled() ? invoiceFormStyle.disableButtonContainer : invoiceFormStyle.buttonContainer}
                            onClick={this.handleUploadInvoice}
                            data-test-id="save-btn"
                        >
                            <Typography style={this.checkIsDisabled() ? invoiceFormStyle.disabledButtonText : invoiceFormStyle.buttonText}>
                                Save
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
            </Modal>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const activeTab = this.props.navigation ? this.props.navigation.getParam('orgTab') : 'select-plan';
        const tabsData = [
            {
                id: 1,
                text: "Select Plan",
                icon: plan,
                activeIcon: plan_active,
                component: <Plans navigation={this.props.navigation} id={""} showMonthYearToggle={true} />,
                isActive: activeTab === "select-plan",
                link: "/select-plan"
            },
            {
                id: 2,
                text: "Confirm Billing",
                icon: bill,
                activeIcon: bill_active,
                component: <Billing navigation={this.props.navigation} id={""} />,
                isActive: activeTab === "confirm-billing",
                link: "/confirm-billing"
            },
            {
                id: 3,
                text: "Invoices",
                icon: invoice,
                activeIcon: invoice_active,
                component: <Invoices navigation={this.props.navigation} id={""} />,
                isActive: activeTab === "invoices",
                link: "/invoices"
            },
        ];
        return (
            <>
                <Layout>
                    <InnerContainer>
                        <PlanTabsSection>
                            <PlanTabs>
                                {tabsData.map((tab) => {
                                    return tab.isActive ? (
                                        <PlanTabItemSelected
                                            key={`selected_tab_${tab.id}`}
                                        >
                                            <PlanIcon><img src={tab.activeIcon} alt="" /></PlanIcon>
                                            <PlanTabTextSelected>{tab.text}</PlanTabTextSelected>
                                        </PlanTabItemSelected>
                                    ) : (
                                        <PlanTabItem
                                            key={`noneselected_tab_${tab.id}`}
                                        >
                                            <PlanTabLink to={OrgBaseUrl + tab.link}>
                                                <PlanIcon><img src={tab.icon} alt="" /></PlanIcon>
                                                <PlanTabText>{tab.text}</PlanTabText>
                                            </PlanTabLink>
                                        </PlanTabItem>
                                    );
                                })}
                            </PlanTabs>
                        </PlanTabsSection>

                        {tabsData.map((content) => {
                            return <PlanTabContent key={`content${content.id}`}>{content.isActive ? content.component : ""}</PlanTabContent>
                        })}

                        {this.renderUploadInvoiceButton(activeTab)}                            
                        {this.renderUploadInvoiceModal()}

                        <Modal
                            open={this.state.completedTaskModal}
                            onClose={()=>{
                                this.setState({completedTaskModal: false});
                            }}
                            style={invoiceFormStyle.modalWrapper}
                            data-testid="complete-task-modal"
                        >
                            <Paper style={invoiceFormStyle.completedModalPaper}>
                                <img src={successCheck} />
                                <Typography style={invoiceFormStyle.sucsessTitle}>{"Success!"}</Typography>
                                <Typography style={invoiceFormStyle.successMessage}>{"Your invoice has now been successfully uploaded."}</Typography>
                            </Paper>
                        </Modal>
                    </InnerContainer>
                </Layout>
            </>
        );
        // Customizable Area End
    }

}

// Customizable Area Start
const invoiceFormStyle = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        width: '437px',
        maxWidth: "100%",
        padding: '16px',
        borderRadius: '12px',
        overflow: 'scroll',
        maxHeight: '90vh',
        // scrollbarWidth: 'none',
        // msOverflowStyle: 'none'
    },
    headcontainer: {
        width: '100%',
        height: '41px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginbottom: '16px'
    },
    title: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "25.2px",
        fontSize: '18px',
    },
    datetimeinput: {
        width: '100%',
        display: 'flex',
        padding: '8px 13px',
        alignItems: 'center',
        alignSelf: 'stretch',
        border: "#00000024 solid 1px",
        borderRadius: "8px",
        cursor: "pointer",
        backgroundColor: "#FAFAFA",
        height: '48px'
    },
    arrowDiv: {
        height: 26,
        width: 26,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modelSubpopper: {
        maxHeight: '271px',
        borderRadius: "8px",
        border: "1px solid #D9D9D9",
        background: "#FFF",
        borderBottom: 'none',
        boxShadow: 'none !important',
        padding: '0px !important',
    },
    autoComplete: {
        borderRadius: '8px',
        border: "1px solid #D9D9D9",
        padding: '11px',
        boxShadow: 'none',
        backgroundColor: "#FAFAFA"
    },
    optionName: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        fontSize: '14px'
    },
    inputField:{ 
        border: "1px solid #D9D9D9", 
        height: '48px', 
        width: '100%',
        borderRadius: '8px',
        fontSize: '15px'
    },
    buttonContainer:{
        background:'#43D270', 
        padding: '8px 12px', 
        borderRadius:'8px', 
        minWidth: '127px', 
        alignItems:'center', 
        justifyContent:'center',
        display:'flex'
    },
    buttonText:{ 
        fontSize: '12px', 
        lineHeight: '19.2px', 
        fontWeight: 700, 
        color: '#34373A', 
    },
    disableButtonContainer:{
        background:'#B1B3C3', 
        padding: '8px 12px', 
        borderRadius:'8px', 
        minWidth: '127px', 
        alignItems:'center', 
        justifyContent:'center',
        display:'flex'
    },
    disabledButtonText: {
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 700,
        color: '#34373A',
    },
    completedModalPaper: {
        width: '100%',
        maxWidth: "455px",
        padding: '40px 30px 20px',
        height: '241px',
        borderRadius: '12px',
        overflow: 'scroll' as 'scroll',
        scrollbarWidth: 'none' as 'none',
        msOverflowStyle: 'none' as 'none',
        textAlign: 'center' as 'center'
    },
    modalWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    sucsessTitle: {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '30px',
        color: '#0F172A',
        textAlign: 'center' as 'center',
        margin: '30px 0 10px'
    },
    successMessage: {
        fontSize: '17px',
        lineHeight: '26px',
        color: '#34373A',
        textAlign: 'center' as 'center'
    }
}

const CustomButton = styled('button')({
    borderRadius: "8px",
    backgroundColor: "#43d270",
    maxWidth: "150px",
    height: "40px",
    display: "flex",
    flexDirection: "row",
    padding: "8px 8px 8px 12px",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontFamily: "Inter",
    border: "none",
    cursor: "pointer",
    marginRight: "28px",
    position: 'absolute',
    top: '30px',
    right: '50px',
    '@media (max-width:600px)': {
        marginRight: '0px !important'
    }
});
const CustomTypography = styled("b")({
    display: "inline-block",
});
const InputLabel = styled(Typography)({
    fontSize:'15px',
    lineHeight: '22px',
    color: '#34373A',
    fontWeight: 500,
    fontFamily:'Lato',
    marginBottom: '8px'
});

const InputField = styled(TextField)({
    width: '100%',
    borderRadius: '8px'
});
// Customizable Area End
