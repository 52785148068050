import React, { useRef, useState } from "react";
import { Box, Button, Typography, LinearProgress, IconButton } from "@mui/material";
import { useDropzone } from "react-dropzone";

const CloudUpload = require('./CloudUpload.svg');
const CloseCircleIcon = require('./CloseCircleIcon.svg');


interface FileUploaderProps {
  onSelect?: (files: File[]) => void;
  allowMultiple?: boolean;
  allowFolderSelection?: boolean;
}

const FileUploader = ({ onSelect = () => { }, allowMultiple = false, allowFolderSelection = false }: FileUploaderProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const folderInputRef = useRef<HTMLInputElement>(null);

  const onDrop = (acceptedFiles: File[]) => {
    setFiles(prevProps => ([...prevProps, ...acceptedFiles]));
    onSelect([...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: allowMultiple });

  const removeFile = (index:number) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  return (
    <Box sx={style.container}>
      {allowFolderSelection ? (
        <Box {...getRootProps()} sx={style.dropableContainer}>
          <input
            type="file"
            style={{ display: "none" }}
            multiple
            {...({ webkitdirectory: "true", directory: "true" }) as any}
            ref={folderInputRef}
            onChange={(e) => onDrop(Array.from(e.target.files || []))}
          />
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onClick={()=>{folderInputRef.current?.click();}}>
            <img src={CloudUpload} style={{ width: '34px', height: '34px', marginBottom: '10px' }} />
            <Typography style={style.label}>
              Drag your folders here
            </Typography>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <div style={{ background: '#DBDADF', height: '1px', width: '66px' }} />
              <Typography style={{ fontSize: '14px', lineHeight: '22.4px', fontWeight: 700, fontFamily: 'Lato', marginRight: '10px', marginLeft: '10px', color: '#DBDADF' }}>or</Typography>
              <div style={{ background: '#DBDADF', height: '1px', width: '66px' }} />
            </Box>
            <Button
              variant="text"
              style={style.buttonContainer}
              onClick={() => {
                folderInputRef.current?.click(); 
              }}>
              Browse Files
            </Button>
          </Box>
        </Box>
      ) : (
        <Box {...getRootProps()} sx={style.dropableContainer}>
          <input {...getInputProps()} />
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={CloudUpload} style={{ width: '34px', height: '34px', marginBottom: '10px' }} />
            <Typography style={style.label}>
              Drag your files, documents here
            </Typography>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <div style={{ background: '#DBDADF', height: '1px', width: '66px' }} />
              <Typography style={{ fontSize: '14px', lineHeight: '22.4px', fontWeight: 700, fontFamily: 'Lato', marginRight: '10px', marginLeft: '10px', color: '#DBDADF' }}>or</Typography>
              <div style={{ background: '#DBDADF', height: '1px', width: '66px' }} />
            </Box>
            <Button
              variant="text"
              style={style.buttonContainer}>
              Browse Files
            </Button>
          </Box>
        </Box>
      )}

      <Box mt={2}>
        {files.map((fileData: any, index: number) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            sx={{
              borderRadius: "8px",
              padding: "10px",
              backgroundColor: "#FFFFFF",
              border: '1px solid #F1F5F9',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                marginRight: "10px",
              }}
            >
              {fileData.name}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography style={{ fontSize: '10px', lineHeight: '16px', fontWeight: 400, fontFamily: 'Lato', textAlign: 'right', marginRight: '16px', color: '#7D7D7F' }}>
                {Math.floor(fileData.size / 1024)} KB
              </Typography>

              <IconButton size="small" onClick={() => removeFile(index)}>
                <img src={CloseCircleIcon} style={{ width: '14px', height: '14px' }} />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const style = {
  container: {
    display: 'flex',
    width: "100%",
    borderRadius: "10px",
    flexDirection: 'column',
  },
  dropableContainer: {
    display: 'flex',
    width: "100%",
    alignItems: 'center',
    justifyContent: 'center',
    border: "2px dashed #ccc",
    borderRadius: "10px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    background: '#FAFAFA'
  },
  buttonContainer: {
    fontSize: '12px',
    lineHeight: '28px',
    fontWeight: 700,
    fontFamily: 'Inter',
    textAlign: 'center',
    color: '#329E54'
  } as React.CSSProperties,
  label: {
    fontSize: '12px',
    lineHeight: '19.2px',
    fontWeight: 400,
    fontFamily: 'Lato',
    textAlign: 'center',
    color: '#7D7D7F',
    marginBottom: '8px',
  } as React.CSSProperties,
}

export default FileUploader;