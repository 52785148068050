import moment from "moment";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props {
    navigation: any;
}

type TaskDetailsType = {
    id: number
    task_name: string;
    start_date: string;
    end_date: string;
    criticality: string;
    assignees: number[];
    completion_percentage: number;
    task_description: string;
    sub_category_id: number;
    status: string;
}

type CommentType = {
    account_id: number;
    user_name: string;
    profile_image: string;
    created_at: string;
    comment_message: string;
}

interface S {
    taskDetails: TaskDetailsType | null;
    subCategoriesList: { id: number; sub_category_name: string; }[];
    assigneeList: { id: number; first_name: string; last_name: string; }[];
    selectedSubCategory: number;
    error: boolean;
    selectedAssignee: { id: number; first_name: string; last_name: string; }[];
    startDate: Date | null;
    endDate: Date | null;
    criticalValue: string;
    description: string;
    userProfileUrl: string;
    comment: string;
    isProtfolioManager: boolean;
    status: string;
    isEditable: boolean;
    commentsList: CommentType[];
    isLoading: boolean;
    completedTaskModal:boolean;
    statusSuccessMessage: string;
    percentage: number;
    openConfirmationModal: boolean;
    alert: {
        isOpen: boolean;
        message: string;
    }
}

interface SS {

}
// Customizable Area End

export default class TaskDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    account_id: number;
    company_id: number;
    taskDetailsApiCallId: string = '';
    commentApiCallId: string = '';
    updateAPICallID: string = '';
    updateTaskStatusApiCallId: string = '';
    getLogProgressApiCallId: string = '';
    updateLogProgressApiCallId: string = '';
    criticalOptions = [
        { value: 'critical', label: 'Critical' },
        { value: 'major', label: 'Major' },
        { value: 'minor', label: 'Minor' },
      ]
  
    statusOptionsPortfolioManager = [
        { value: 'yet_to_start', label: 'Yet to Start' },
        { value: 'in_progress', label: 'In progress' },
        { value: 'completed', label: 'Competed' },
      ]
  
    statusOptions = [
        { value: 'yet_to_start', label: 'Yet to Start' },
        { value: 'in_progress', label: 'In progress' },
      ]
    loggedInUserName: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
        const orgDetails = JSON.parse(localStorage.getItem("organization") || "{}");
        this.account_id = userDetails.meta.id;
        this.company_id = orgDetails.id;
        this.loggedInUserName = userDetails.meta?.first_name ? userDetails.meta?.first_name[0] : '';
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            taskDetails: null,
            subCategoriesList: [],
            assigneeList: [],
            selectedSubCategory: 0,
            error: false,
            selectedAssignee: [],
            startDate: null,
            endDate: null,
            criticalValue: '',
            description: '',
            userProfileUrl: userDetails.meta.photo ?? '',
            comment: '',
            isProtfolioManager: userDetails.meta.user_account_type === "portfolio_manager",
            status: "yet_to_start",
            isEditable: false,
            commentsList:[],
            isLoading: false,
            completedTaskModal: false,
            statusSuccessMessage:'',
            percentage:0,
            openConfirmationModal: false,
            alert: {
                isOpen: false,
                message: '',
            }
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const requestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )

            if (this.taskDetailsApiCallId === requestCallId) {
                this.handleTaskDetailsResponse(responseJson)
            }

            this.handleAPIResponse(requestCallId, responseJson);
        }
    }

    handleTaskDetailsResponse = (responseJson: any) => {
        const taskId = this.props.navigation?.getParam('taskId');
        this.setState({
            taskDetails: { ...responseJson.task_data, id: Number(taskId), status: responseJson.status ?? 'yet_to_start' },
            commentsList: responseJson.comment.reverse(),
            status: responseJson.status ?? 'yet_to_start',
            isLoading: false,
        });

        if (responseJson?.sub_categories?.length > 0) {
            this.setState({ subCategoriesList: responseJson.sub_categories });
        }

        if (responseJson?.assignees?.length > 0) {
            this.setState({ assigneeList: responseJson.assignees });
        }
        this.setTaskDetails(responseJson.task_data);
    }

    handleAPIResponse = async (requestCallId: any, responseJson: any) => {
        switch (requestCallId) {
            case this.commentApiCallId:
                if (responseJson.success) {
                    await this.getTaskDetails();
                    this.setState({ comment: '' })
                }
                break;

            case this.updateAPICallID:
                if (responseJson.success) {
                    await this.getTaskDetails();
                    localStorage.setItem('editTaskEnabled', 'false');
                    this.setState({ isEditable: false });
                }
                break;

            case this.updateTaskStatusApiCallId:
                if(responseJson.success === "true"){
                    this.setState({
                        isLoading: false,
                        statusSuccessMessage: responseJson.message,
                        completedTaskModal: true
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        alert: {
                            isOpen: true,
                            message: responseJson.message,
                        }, 
                        status: this.state.taskDetails?.status ?? 'yet_to_start'
                    });
                }
                break;

            case this.getLogProgressApiCallId:
                this.setState({ percentage: responseJson.completion_percentage ?? 0 })
                break;

            case this.updateLogProgressApiCallId:
                await this.getLogProgress();
                break;

            default:
                break;
        }
    }

    setTaskDetails = (taskData: TaskDetailsType) => {
        const users = taskData.assignees.length > 0 ? this.state.assigneeList.filter(option => taskData.assignees.includes(option.id)) : []
        this.setState({
            selectedSubCategory: taskData.sub_category_id,
            selectedAssignee: users,
            startDate: new Date(taskData.start_date),
            endDate: new Date(taskData.end_date),
            criticalValue: taskData.criticality,
            description: taskData.task_description ?? ''
        });
    }

    async componentDidMount() {
        super.componentDidMount();
        window.scrollTo(0, 0);
        await this.getTaskDetails();
        await this.getLogProgress();

        const editTaskEnabled = await localStorage.getItem('editTaskEnabled');
        this.setState({isEditable: editTaskEnabled === 'true'});
    }

    async componentWillUnmount() {
        if(this.state.isEditable){
            localStorage.setItem('editTaskEnabled', 'false');
        }
    }

    getTaskDetails = async () => {
        const taskId = this.props.navigation?.getParam('taskId');

        if (taskId) {
            const body = {
                task_id: taskId,
                account_id: this.account_id,
                company_id: this.company_id
            };

            this.taskDetailsApiCallId = await this.apiCall({
                endPoint: configJSON.getEditTaskEndPoint,
                apiBody: body,
                apiMethod: configJSON.exampleAPiMethod
            });
        }
    }

    getLogProgress = async () => {
        const taskId = this.props.navigation?.getParam('taskId');
        if (taskId) {
            this.getLogProgressApiCallId = await this.apiCall({
                endPoint: `bx_block_goalmanagement/todo_task_list/show_to_do_task?action_plan_task_id=${taskId}&account_id=${this.account_id}`,
                apiBody: null,
                apiMethod: configJSON.validationApiMethodType
            });
        }
    }

    apiCall = async (data: { endPoint: string; apiBody: any; apiMethod: string }) => {
        const { endPoint, apiBody, apiMethod } = data;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("authToken")
        };

        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

        apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), apiMethod ? apiMethod : configJSON.validationApiMethodType);

        { apiBody && apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(apiBody)); }

        runEngine.sendMessage(apiRequest.id, apiRequest);
        return apiRequest.messageId;
    };

    handleStartDate = (date: Date | null) => {
        this.setState({
            startDate: date

        })
    }
    handleChangeEndDate = (date: Date | null) => {
        this.setState({
            endDate: date
        })
    }

    handleComment = async () => {
        const body = {
            account_id: this.account_id,
            action_plan_task_id: this.state.taskDetails?.id,
            comment_message: this.state.comment,
        };
        this.setState({isLoading:true})
        this.commentApiCallId = await this.apiCall({
            endPoint: configJSON.commentEndPoint,
            apiBody: body,
            apiMethod: configJSON.exampleAPiMethod
        });
    }

    checkIsDisabled = () => {
        if (this.state.isProtfolioManager) {
            return !this.state.isEditable;
        }
        return true;
    }

    updateTask = async () => {
        const body = {
            task_id: this.state.taskDetails?.id,
            account_id: this.account_id,
            company_id: this.company_id,
            task_name: this.state.taskDetails?.task_name,
            task_description: this.state.description,
            start_date: moment(this.state.startDate).format("DD-MM-YYYY"),
            end_date: moment(this.state.endDate).format("DD-MM-YYYY"),
            criticality: this.state.criticalValue,
            status: this.state.status,
            assignees:this.state.selectedAssignee.map(data => data.id),
        };
        this.setState({isLoading:true})
        this.updateAPICallID = await this.apiCall({
            apiBody: body,
            endPoint: configJSON.updateTaskEndPoint,
            apiMethod: configJSON.updateMethod
        });

        this.updateLogProgressApiCallId = await this.apiCall({
            apiBody: null,
            endPoint: `bx_block_goalmanagement/todo_task_list/change_task_progress_percentage?action_plan_task_id=${this.state.taskDetails?.id}&account_id=${this.account_id}&task_progress_percentage=${this.state.percentage}`,
            apiMethod: configJSON.updateMethod
        });
    }

    updateTaskStatus = async () => {
        const body = {
            task_id: this.state.taskDetails?.id,
            account_id: this.account_id,
            company_id: this.company_id,
            status: this.state.status
        };
        this.setState({isLoading:true})
        this.updateTaskStatusApiCallId = await this.apiCall({
            apiBody: body,
            endPoint: configJSON.updateTaskStatusEndPoint,
            apiMethod: configJSON.updateMethod
        });
    }

    handleCloseCustomSnackbar = () => {
        this.setState({
          alert: {
            ...this.state.alert,
            isOpen: false,
          }
        });
      }

    // Customizable Area End
}