Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  exports.validationApiContentType = "application/json";
  exports.getAPIMethod = "GET";
  exports.postAPIMethod = "POST";

  exports.createFolderLabel = 'Create a folder'
  exports.createFolderModalSubText = 'Please provide a name for the new folder, select a category, and then save.'
  exports.nameText = 'Name';
  exports.namePlaceholderText = 'Untitled folder';
  exports.selectCategory = 'Select Category';
  exports.selectOne = "Select one";
  exports.emptyStateDescription = "No file found.  Click the ‘upload’ button to upload files or create a new folder"
  
  exports.companyDataEndPoint = "bx_block_documentstorage/zoho_work_drive/fetch_company_data";
  exports.createFolderEndPoint = 'bx_block_documentstorage/zoho_work_drive/create_folder';
  exports.folderDataEndPoint = 'bx_block_documentstorage/zoho_work_drive/fetch_folder_data';
  exports.uploadFileEndPoint = 'bx_block_documentstorage/zoho_work_drive/upload_file';
  exports.deleteEndPoint = "bx_block_documentstorage/zoho_work_drive/trash_untrash";
  exports.downloadEndPoint = "bx_block_documentstorage/zoho_work_drive/download_file";
  exports.downloadFolderEndPoint = "bx_block_documentstorage/zoho_work_drive/download_zip";
  // Customizable Area End
  