import React from "react";
// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import ReportBuilderFormController, {
    Props,
    configJSON,
} from "./ReportBuilderFormController";

import Layout from "../../../dashboard/src/Layout.web";
import { Box, TextField, Typography } from "@material-ui/core";
import CustomizedSteppers from "../../../../components/src/CustomizedSteppers";
import { uploadIcon } from "../assets";
import { checkedIcon } from "../../../email-account-registration/src/assets";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

// Customizable Area End



// Customizable Area Start
export default class ReportBuilderForm extends ReportBuilderFormController {
    constructor(props: Props) {
        super(props);
    }

    renderGeneralForm(){
        return (
            <>
                <Typography style={webStyle.subTitleText}>Welcome Statement</Typography>

                <Typography style={webStyle.inputLabel}>Mission</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-mission"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                mission: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.generalObj.mission}
                />

                <Typography style={webStyle.inputLabel}>Vision</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-vision"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                vision: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.generalObj.vision}
                />

                <Typography style={webStyle.inputLabel}>Welcome Statement</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-welcome-statement"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                welcome_statement: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.generalObj.welcome_statement}
                />

                <Typography style={webStyle.inputLabel}>Sustainability snapshot</Typography>
                <Box style={webStyle.fileUploadContainer} onClick={this.handleClick} data-test-id="sustainability-file-input">
                    <Typography style={webStyle.chooseText}>Choose File</Typography>
                    <img src={uploadIcon} style={{ width: '43px', height: '43px', cursor: 'pointer' }} />
                    <input
                        type="file"
                        ref={this.fileInputRef}
                        style={{ display: "none" }}
                        onChange={this.handleFileChange}
                        accept=".jpg,.png,.pdf"
                        multiple={false}
                    />
                </Box>

                <Typography style={webStyle.inputLabel}>Key highlights</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-key-highlights"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                key_highlights: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.generalObj.key_highlights}
                />

                <Typography style={webStyle.inputLabel}>Philosophy</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-philosophy"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                philosophy: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.generalObj.philosophy}
                />

                <hr color="#EBEBEB" style={{marginTop: '35px', marginBottom: '35px'}} />

                <Typography style={webStyle.subTitleText}>Stakeholder Engagement</Typography>

                <Typography style={webStyle.inputLabel}>Engagement 01</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-engagement1"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                engagement1: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.generalObj.engagement1}
                />

                <Typography style={webStyle.inputLabel}>Engagement 02</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-engagement2"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                engagement2: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.generalObj.engagement2}
                />

                <hr color="#EBEBEB" style={{marginTop: '35px', marginBottom: '35px'}} />

                <Typography style={webStyle.subTitleText}>Awards, Accolades, Memberships</Typography>

                <Typography style={webStyle.inputLabel}>Awards</Typography>
                <Box data-test-id="awards-list">
                    {Array(this.state.awardsListCount).fill('').map((item, index) => {
                        return (
                            <Box key={index} style={{ marginBottom: index === this.state.awardsListCount - 1 ? '0px' : '24px' }}>
                                <TextField
                                    variant="outlined"
                                    inputProps={{ style: webStyle.inputPropsStyle }}
                                    InputProps={{ style: { ...webStyle.inputStyle, marginBottom: '10px' } }}
                                    fullWidth
                                    placeholder="Type here..."
                                    onChange={(e) => {
                                        const list = [...this.state.generalObj.awards];
                                        list[index] = {
                                            ...list[index],
                                            award: e.target.value,
                                        }
                                        this.setState(prevProps => ({
                                            generalObj: {
                                                ...prevProps.generalObj,
                                                awards: list,
                                            },
                                        }));
                                    }}
                                />

                                <Box style={webStyle.fileUploadContainer} onClick={this.awardUploadHandler}>
                                    <Typography style={webStyle.chooseText}>Choose File</Typography>
                                    <img src={uploadIcon} style={{ width: '43px', height: '43px', cursor: 'pointer' }} />
                                    <input
                                        type="file"
                                        ref={this.awardFileInputRef}
                                        style={{ display: "none" }}
                                        onChange={e => this.awardsFileHandler(e, index)}
                                        accept=".jpg,.png,.pdf"
                                        multiple={false}
                                    />
                                </Box>
                            </Box>
                        )
                    })}
                </Box>

                <Typography
                    style={webStyle.addMoreBtn}
                    onClick={() => {
                        this.setState(prevProps => ({
                            awardsListCount: prevProps.awardsListCount + 1,
                        }));
                    }}
                    data-test-id="add-more-award"
                >
                    + Add More
                </Typography>

                <Typography style={webStyle.inputLabel}>Accolades</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-accolades"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            generalObj: {
                                ...prevProps.generalObj,
                                accolades: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.generalObj.accolades}
                />

                <Typography style={{...webStyle.inputLabel, marginTop: '23px'}}>Membership</Typography>
                <Box data-test-id="membership-list">
                    {Array(this.state.memershipListCount).fill('').map((item, index) => {
                        return (
                            <Box key={index} style={{ marginBottom: index === this.state.memershipListCount - 1 ? '0px' : '24px' }}>
                                <TextField
                                    variant="outlined"
                                    inputProps={{ style: webStyle.inputPropsStyle }}
                                    InputProps={{ style: { ...webStyle.inputStyle, marginBottom: '10px' } }}
                                    fullWidth
                                    placeholder="Type here..."
                                    onChange={(e) => {
                                        const list = [...this.state.generalObj.memberships];
                                        list[index] = {
                                            ...list[index],
                                            membership: e.target.value,
                                        }
                                        this.setState(prevProps => ({
                                            generalObj: {
                                                ...prevProps.generalObj,
                                                memberships: list,
                                            },
                                        }));
                                    }}
                                />

                                <TextField
                                    variant="outlined"
                                    inputProps={{ style: webStyle.inputPropsStyle }}
                                    InputProps={{ style: { ...webStyle.inputStyle, marginBottom: '10px' } }}
                                    fullWidth
                                    placeholder="Type here..."
                                    onChange={(e) => {
                                        const list = [...this.state.generalObj.memberships];
                                        list[index] = {
                                            ...list[index],
                                            membership_details: e.target.value,
                                        }
                                        this.setState(prevProps => ({
                                            generalObj: {
                                                ...prevProps.generalObj,
                                                memberships: list,
                                            },
                                        }));
                                    }}
                                />
                            </Box>
                        )
                    })}
                </Box>
                <Typography
                    style={{...webStyle.addMoreBtn, marginBottom: 0}}
                    onClick={() => {
                        this.setState(prevProps => ({
                            memershipListCount: prevProps.memershipListCount + 1,
                        }));
                    }}
                    data-test-id="add-more-membership"
                >
                    + Add More
                </Typography>

                <hr color="#EBEBEB" style={{marginTop: '35px', marginBottom: '35px'}} />

                <Typography style={webStyle.subTitleText}>4 Future Initiatives/ Outlook</Typography>

                <Box style={{ display: 'flex', alignItems: 'center', gap: '26px', marginBottom: '22px' }}>
                    <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Typography style={webStyle.inputLabel}>Initiatives 01</Typography>
                        <TextField
                            variant="outlined"
                            inputProps={{ style: webStyle.inputPropsStyle }}
                            InputProps={{ style: webStyle.inputStyle }}
                            fullWidth
                            placeholder="Type here..."
                            onChange={(e) => {
                                this.setState(prevProps => ({
                                    generalObj: {
                                        ...prevProps.generalObj,
                                        initative1: e.target.value,
                                    },
                                }));
                            }}
                            value={this.state.generalObj.initative1}
                            data-test-id="input-initiative1"
                        />
                    </Box>

                    <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Typography style={webStyle.inputLabel}>Initiatives 02</Typography>
                        <TextField
                            variant="outlined"
                            inputProps={{ style: webStyle.inputPropsStyle }}
                            InputProps={{ style: webStyle.inputStyle }}
                            fullWidth
                            placeholder="Type here..."
                            onChange={(e) => {
                                this.setState(prevProps => ({
                                    generalObj: {
                                        ...prevProps.generalObj,
                                        initative2: e.target.value,
                                    },
                                }));
                            }}
                            value={this.state.generalObj.initative2}
                            data-test-id="input-initiative2"
                        />
                    </Box>
                </Box>

                <Box style={{ display: 'flex', alignItems: 'center', gap: '26px' }}>
                    <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Typography style={webStyle.inputLabel}>Initiatives 03</Typography>
                        <TextField
                            variant="outlined"
                            inputProps={{ style: webStyle.inputPropsStyle }}
                            InputProps={{ style: webStyle.inputStyle }}
                            fullWidth
                            placeholder="Type here..."
                            onChange={(e) => {
                                this.setState(prevProps => ({
                                    generalObj: {
                                        ...prevProps.generalObj,
                                        initative3: e.target.value,
                                    },
                                }));
                            }}
                            value={this.state.generalObj.initative3}
                            data-test-id="input-initiative3"
                        />
                    </Box>

                    <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Typography style={webStyle.inputLabel}>Initiatives 04</Typography>
                        <TextField
                            variant="outlined"
                            inputProps={{ style: webStyle.inputPropsStyle }}
                            InputProps={{ style: webStyle.inputStyle }}
                            fullWidth
                            placeholder="Type here..."
                            onChange={(e) => {
                                this.setState(prevProps => ({
                                    generalObj: {
                                        ...prevProps.generalObj,
                                        initative4: e.target.value,
                                    },
                                }));
                            }}
                            value={this.state.generalObj.initative4}
                            data-test-id="input-initiative4"
                        />
                    </Box>
                </Box>

                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '60px' }}>
                    <Box style={webStyle.buttonContainer}>
                        <Typography style={webStyle.buttonText}>
                            Cancel
                        </Typography>
                    </Box>

                    <Box style={{...webStyle.buttonContainer, background: '#43D270'}} onClick={()=>{this.setState({activeStep: 1})}} data-test-id="general-proceed-btn">
                        <Typography style={webStyle.buttonText}>
                            Proceed
                        </Typography>
                    </Box>
                </Box>
            </>
        )
    }

    renderESGPerformanceForm() {
        return (
            <>
                <Typography style={webStyle.subTitleText}>Environmental Stewardship</Typography>

                <Typography style={webStyle.inputLabel}>Circular Economy Initiatives</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-circular-economy"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                circular_economy_initatives: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.esgPerformanceObj.circular_economy_initatives}
                />

                <Typography style={webStyle.inputLabel}>Membership</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-membership"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                membership: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.esgPerformanceObj.membership}
                />

                <Typography style={webStyle.inputLabel}>Resource Efficiency</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-efficiency"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                resource_efficiencey: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.esgPerformanceObj.resource_efficiencey}
                />

                <hr color="#EBEBEB" style={{ marginTop: '35px', marginBottom: '35px' }} />

                <Typography style={webStyle.subTitleText}>Employee Well-being</Typography>

                <Typography style={webStyle.inputLabel}>Well-being</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-well-being"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                well_being: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.esgPerformanceObj.well_being}
                />

                <Typography style={webStyle.inputLabel}>Partnerships</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-partnerships"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                partnerships: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.esgPerformanceObj.partnerships}
                />

                <Typography style={webStyle.inputLabel}>Community</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-community"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                community: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.esgPerformanceObj.community}
                />

                <Typography style={webStyle.inputLabel}>Diversity Efforts</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-diversity-efforts"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                diversity_efforts: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.esgPerformanceObj.diversity_efforts}
                />

                <hr color="#EBEBEB" style={{ marginTop: '35px', marginBottom: '35px' }} />

                <Typography style={webStyle.subTitleText}>Transparency and accountability</Typography>

                <Typography style={webStyle.inputLabel}>Certifications</Typography>
                <Box data-test-id="certificates-list">
                    {Array(this.state.certificationsListCount).fill('').map((item, index) => {
                        return (
                            <Box key={index} style={{ marginBottom: index === this.state.certificationsListCount - 1 ? '0px' : '24px' }}>
                                <TextField
                                    variant="outlined"
                                    inputProps={{ style: webStyle.inputPropsStyle }}
                                    InputProps={{ style: { ...webStyle.inputStyle, marginBottom: '10px' } }}
                                    fullWidth
                                    placeholder="Type here..."
                                    onChange={(e) => {
                                        const list = [...this.state.esgPerformanceObj.certifications];
                                        list[index] = {
                                            ...list[index],
                                            title: e.target.value,
                                        }
                                        this.setState(prevProps => ({
                                            esgPerformanceObj: {
                                                ...prevProps.esgPerformanceObj,
                                                certifications: list,
                                            },
                                        }));
                                    }}
                                />

                                <Box style={webStyle.fileUploadContainer} onClick={this.certificateUploadHandler}>
                                    <Typography style={webStyle.chooseText}>Choose File</Typography>
                                    <img src={uploadIcon} style={{ width: '43px', height: '43px', cursor: 'pointer' }} />
                                    <input
                                        type="file"
                                        ref={this.certificateFileInputRef}
                                        style={{ display: "none" }}
                                        onChange={e => this.certificateFileHandler(e, index)}
                                        accept=".jpg,.png,.pdf"
                                        multiple={false}
                                    />
                                </Box>
                            </Box>
                        )
                    })}
                </Box>

                <Typography
                    style={webStyle.addMoreBtn}
                    onClick={() => {
                        this.setState(prevProps => ({
                            certificationsListCount: prevProps.certificationsListCount + 1,
                        }));
                    }}
                    data-test-id="add-more-certificate"
                >
                    + Add More
                </Typography>

                <Typography style={webStyle.inputLabel}>Ethical Policies</Typography>
                <Box data-test-id="policies-list">
                    {Array(this.state.ethicalPoliciesListCount).fill('').map((item, index) => {
                        return (
                            <Box key={index} style={{ marginBottom: index === this.state.ethicalPoliciesListCount - 1 ? '0px' : '24px' }}>
                                <TextField
                                    variant="outlined"
                                    inputProps={{ style: webStyle.inputPropsStyle }}
                                    InputProps={{ style: webStyle.inputStyle }}
                                    fullWidth
                                    placeholder="Type here..."
                                    onChange={(e) => {
                                        const list = [...this.state.esgPerformanceObj.ethical_policies];
                                        list[index] = e.target.value;

                                        this.setState(prevProps => ({
                                            esgPerformanceObj: {
                                                ...prevProps.esgPerformanceObj,
                                                ethical_policies: list,
                                            },
                                        }));
                                    }}
                                />
                            </Box>
                        )
                    })}
                </Box>
                <Typography
                    style={webStyle.addMoreBtn}
                    onClick={() => {
                        this.setState(prevProps => ({
                            ethicalPoliciesListCount: prevProps.ethicalPoliciesListCount + 1,
                        }));
                    }}
                    data-test-id="add-more-policies"
                >
                    + Add More
                </Typography>

                <Typography style={webStyle.inputLabel}>Data security/ standards</Typography>
                <TextField
                    variant="outlined"
                    inputProps={{ style: webStyle.inputPropsStyle }}
                    InputProps={{ style: webStyle.inputStyle }}
                    fullWidth
                    placeholder="Type here..."
                    data-test-id="input-data-security"
                    onChange={(e) => {
                        this.setState(prevProps => ({
                            esgPerformanceObj: {
                                ...prevProps.esgPerformanceObj,
                                data_security: e.target.value,
                            },
                        }));
                    }}
                    value={this.state.esgPerformanceObj.data_security}
                />

                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '60px' }}>
                    <Box style={webStyle.buttonContainer} onClick={()=> this.setState({activeStep: 0})} data-test-id="back-btn">
                        <Typography style={webStyle.buttonText}>
                            Back
                        </Typography>
                    </Box>

                    <Box style={{ ...webStyle.buttonContainer, background: '#43D270' }} onClick={() => { this.setState({ activeStep: 2 }) }} data-test-id="proceed-esg-performance-btn">
                        <Typography style={webStyle.buttonText}>
                            Proceed
                        </Typography>
                    </Box>
                </Box>
            </>
        )
    }

    renderUNSDGList(){
        const {unsdgList} = this.state;
        return (
            <>
                <Typography style={{...webStyle.subTitleText, marginBottom: '17px'}}>Select UN SDGs</Typography>
                <Box data-test-id="unsdglist">
                    {unsdgList.map((item, index) => {
                        return (
                            <Box key={index}>
                                <Box key={index} style={{ ...webStyle.unsdgCard, background: item.goal_enabled ? '#F3F3F3' : '#FFF' }}>
                                    <Box style={{ display: 'flex', alignItems: 'center', gap: '31px' }}>
                                        <Box style={{ ...webStyle.iconContainer, background: item.goal_enabled ? '#FFF' : '#F3F3F3' }}>
                                            <img
                                                src={item.goal_icon}
                                                style={{
                                                    width: '19px',
                                                    height: '19px',
                                                    borderRadius: '8px',
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography style={webStyle.goalName}>{item.goal_name}</Typography>
                                            <Typography style={webStyle.goalTitle}>{item.goal_title}</Typography>
                                        </Box>
                                    </Box>

                                    {item.goal_enabled && <img src={checkedIcon} style={{ width: '24px', height: '24px', borderRadius: '12px' }} />}
                                </Box>
                                <hr color="#E6E6E6" style={{ margin: 0 }} />
                            </Box>
                        )
                    })}
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '60px' }}>
                    <Box style={webStyle.buttonContainer} onClick={() => this.setState({ activeStep: 1 })} data-test-id="back-btn-3">
                        <Typography style={webStyle.buttonText}>
                            Back
                        </Typography>
                    </Box>

                    <Box style={{ ...webStyle.buttonContainer, background: '#43D270' }} onClick={() => { this.setState({fillDetails: true}) }} data-test-id="proceed-unsdg-list">
                        <Typography style={webStyle.buttonText}>
                            Proceed
                        </Typography>
                    </Box>
                </Box>
            </>
        )
    }

    renderUNSDGDetails() {
        return (
            <>
                <Typography style={webStyle.subTitleText}>Fill the chosen UN SDGs</Typography>

                {this.state.choosenUNSDG.map((item) => {
                    return (
                        <Box key={item.id}>
                            <Typography style={webStyle.inputLabel}>{item.goal_title}</Typography>
                            <TextField
                                variant="outlined"
                                inputProps={{ style: webStyle.inputPropsStyle }}
                                InputProps={{ style: webStyle.inputStyle }}
                                fullWidth
                                placeholder="Type here..."
                                data-test-id="input-circular-economy"
                                onChange={(e) => {
                                    const list = this.state.choosenUNSDG.map(ele => {
                                        if(ele.goal_id === item.goal_id){
                                            return {
                                                ...ele,
                                                details: e.target.value,
                                            }
                                        }

                                        return ele;
                                    })
                                    this.setState({choosenUNSDG: list});
                                }}
                                value={this.state.choosenUNSDG.find(ele => ele.goal_id === item.goal_id)?.details ?? ''}
                            />
                        </Box>
                    )
                })}

                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '60px' }}>
                    <Box style={webStyle.buttonContainer} onClick={() => this.setState({ activeStep: 2, fillDetails: false })}>
                        <Typography style={webStyle.buttonText}>
                            Back
                        </Typography>
                    </Box>

                    <Box style={{ ...webStyle.buttonContainer, background: '#43D270' }}>
                        <Typography style={webStyle.buttonText}>
                            Submit
                        </Typography>
                    </Box>
                </Box>
            </>
        )
    }

    renderContent():JSX.Element{
        switch (this.state.activeStep) {
            case 0:
                return this.renderGeneralForm();

            case 1:
                return this.renderESGPerformanceForm();

            case 2:
                if(this.state.fillDetails){
                    return this.renderUNSDGDetails();
                }
                return this.renderUNSDGList();

            default:
                return <></>;
        }
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Layout>
                    <Box style={webStyle.headerContainer}>
                        <Typography style={webStyle.titleText}>{configJSON.reportBuilderFormText}</Typography>
                        <Typography style={webStyle.descriptionText}>{configJSON.reportBuilderFormDescription}</Typography>

                        <CustomizedSteppers
                            data-testid="customized-stepper"
                            getSteps={this.tabOptions}
                            getStepContent={(step)=>{
                                this.setState({activeStep: step});
                            }}
                            activeStep={this.state.activeStep}
                            style={{padding: 0}}
                        />
                    </Box>
                    <hr color="#EBEBEB" />
                    <Box style={{padding: '40px 50px', width: '90%'}}>
                        {this.renderContent()}
                    </Box>
                </Layout>
            </ThemeProvider>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const webStyle = {
    headerContainer: {
        marginTop: '32px',
        padding: "0 44px 30px",
    },
    titleText:{
        fontSize: '28px',
        lineHeight: '35px',
        fontWeight: 700,
        fontFamily: 'Lato',
        color: '#34373A',
        marginBottom: '23px',
    },
    descriptionText:{
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 400,
        fontFamily: 'Lato',
        color: '#7D7D7F',
        marginBottom: '10px',
    },
    subTitleText: {
        fontSize: '18px',
        lineHeight: '15.2px',
        fontWeight: 700,
        fontFamily: 'Lato',
        color: '#34373A',
    },
    inputLabel: {
        fontSize: '15px',
        lineHeight: '15.2px',
        fontWeight: 400,
        fontFamily: 'Lato',
        color: '#34373A',
        marginBottom: '8px',
        marginTop: '16px'
    },
    inputStyle:{
        fontSize: '15px',
        color: '#34373A',
        fontFamily: 'Lato',
        fontWeight: 400,
        lineHeight: '24px',
        border: '1px solid #D9D9D9',
    },
    inputPropsStyle: { 
        padding: '14px 12px',
    },
    chooseText:{
        fontSize: '15px',
        color: '#34373A',
        fontFamily: 'Lato',
        fontWeight: 400,
        lineHeight: '24px',
        cursor: 'pointer',
    },
    fileUploadContainer: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between', 
        border: '1px dashed #D9D9D9',
        borderRadius: '8px',
        padding: '18.51px 18.44px',
        cursor: 'pointer'
    },
    addMoreBtn: {
        marginBottom: '24px',
        textAlign: 'right',
        marginTop: '12px',
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 700,
        fontFamily: 'Inter',
        color: '#43D270',
        cursor: 'pointer'
    } as React.CSSProperties,
    buttonContainer:{
        width: '160px',
        height: '40px',
        background: '#F6F6F6',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonText:{
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 700,
        fontFamily: 'Inter',
        color: '#34373A',
        cursor: 'pointer'
    },
    unsdgCard: {
        display: 'flex',
        alignItems: 'center',
        padding: '15px',
        justifyContent: 'space-between',
    },
    iconContainer:{
        width: '48px',
        height: '48px',
        borderRadius: '8px',
        background: '#F3F3F3',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    goalName:{
        fontSize: '12px',
        lineHeight: '19.2px',
        fontWeight: 700,
        fontFamily: 'Lato',
        color: '#7D7D7F',
    },
    goalTitle:{
        fontSize: '18px',
        lineHeight: '11.6px',
        fontWeight: 700,
        fontFamily: 'Lato',
        color: '#34373A',
        marginTop: '2px'
    },
};
// Customizable Area End
