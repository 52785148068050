import React from "react";

import {
  // Customizable Area Start
  Typography
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import { vendor, vendor_active, framwork_active, framworkicon, materiality, materialityActive } from "./assets";
import Layout from "../../dashboard/src/Layout.web";
import Vendoruseraccountmarketplacehyperlinks from "../../vendoruseraccountmarketplacehyperlinks/src/Vendoruseraccountmarketplacehyperlinks.web";
import Cfnzdpusearch from "../../cfnzdpusearch/src/Cfnzdpusearch.web";
import MaterialityMatrixGraph from "../../cfmaterialitymatrixgraph0/src/Cfmaterialitymatrixgraph0";
import { Link } from "react-router-dom";

const InnerContainer = styled("div")({
    width: "100%",
    height: "auto",
});

const HubTabContent = styled('div')({
    width: "100%",
})

const HubTabLink = styled(Link)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
})

const HubTabs = styled('div')({
    maxWidth: '404px',
    display: 'flex',
    gap: '8px',
    '@media(max-width: 450px)': {
        flexDirection: 'column',
        alignItems: 'start'
    },
})

const HubTabText = styled(Typography)({
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    letterSpacing: "0.2px",
    cursor: "pointer",
});

const HubTabTextSelected = styled(HubTabText)({
    color: "#329E54",
    fontWeight: 700,
});

const HubIcon = styled("span")({
    width: "20px",
    height: "20px",
});

const HubTabsSection = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: "8px",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "32px 37px 44px 37px",
    '@media(max-width: 450px)': {
        alignItems: 'end'
    },
});

const HubTabItem = styled("div")({
    display: "flex",
    justifyContent: "center",
    padding: "8px 12px",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
    borderRadius: "8px",
    backgroundColor: "#FFF",
    color: "grey",
    fontSize: "15px",
    height: "40px",
    cursor: "pointer",
});

const HubTabItemSelected = styled("div")({
    display: "flex",
    justifyContent: "center",
    padding: "8px 12px",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
    borderRadius: "8px",
    backgroundColor: "#ECFBF1",
    color: "grey",
    fontSize: "15px",
    cursor: "pointer",
});



const OrgBaseUrl = "/act"

// Customizable Area End

import Multiplelandingpage2Controller, {
  Props,
  configJSON,
} from "./Multiplelandingpage2Controller";

export default class Hub extends Multiplelandingpage2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    const activeTab = this.props.navigation ? this.props.navigation.getParam('orgTab') : 'marketplace';

        const tabsData = [
            {
                id: 1,
                text: configJSON.Marketplace,
                icon: framworkicon,
                activeIcon: framwork_active,
                component: <Vendoruseraccountmarketplacehyperlinks navigation={this.props.navigation} id={""} />,
                isActive: activeTab === "marketplace",
                link: "/marketplace"
            },
            {
                id: 2,
                text: configJSON.IndustryBenchmarking,
                icon: vendor,
                activeIcon: vendor_active,
                component: <Cfnzdpusearch navigation={this.props.navigation} id={""} />,
                isActive: activeTab === "industry-benchmarking",
                link: "/industry-benchmarking"
            },
            {
                id: 3,
                text: configJSON.MaterialityMatrix,
                icon: materiality,
                activeIcon: materialityActive,
                component: (
                    <MaterialityMatrixGraph 
                        id={""} 
                        navigation={undefined} 
                        navigateToMaterialCards={this.navigateToMaterialCards} 
                    />
                ),
                isActive: activeTab === "materiality-matrix",
                link: "/materiality-matrix"
            }
        ];
    return (
      <>
                <Layout>
                    <InnerContainer>
                    {(!window.location.pathname.includes('/act/') && !window.location.pathname.includes('task-list')) ? (
                        <HubTabsSection>
                            <HubTabs>
                                {tabsData.map((tab) => {
                                    return tab.isActive ? (
                                        <HubTabItemSelected
                                            key={`selected_tab_${tab.id}`}
                                        >
                                            <HubIcon><img src={tab.activeIcon} alt="" /></HubIcon>
                                            <HubTabTextSelected>{tab.text}</HubTabTextSelected>
                                        </HubTabItemSelected>
                                    ) : (
                                        <HubTabItem
                                            key={`noneselected_tab_${tab.id}`}
                                        >
                                            <HubTabLink to={OrgBaseUrl + tab.link}>
                                                <HubIcon><img src={tab.icon} alt="" /></HubIcon>
                                                <HubTabText>{tab.text}</HubTabText>
                                            </HubTabLink>
                                        </HubTabItem>
                                    );
                                })}
                            </HubTabs>
                        </HubTabsSection>
                    ) : (
                        <div style={{ height: '52px' }}></div>
                    )}
                        
                        {tabsData.map((content) => {
                            return <HubTabContent key={`content${content.id}`}>{content.isActive ? content.component : ""}</HubTabContent>
                        })}
                    </InnerContainer>
                </Layout>
            </>
    );
    // Customizable Area End
  }

}

// Customizable Area Start
// Customizable Area End
