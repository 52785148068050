import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start


// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ReportBuilderController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    account_id: string;
    company_id: string;

    constructor(props: Props) {
        super(props);
        
        const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
        const orgDetails = JSON.parse(localStorage.getItem("organization") || "{}");
        this.account_id = userDetails.meta.id;
        this.company_id = orgDetails.id;


        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {};

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    navigateToReportBuilderForm = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ReportBuilderForm");
        message.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(message);
    }

    // Customizable Area End
}
