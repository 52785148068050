import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { createRef } from "react";
import { getStorageData } from "../../../../framework/src/Utilities";
// Customizable Area Start


// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeStep: number;
    generalObj : {
        mission:string;
        vision: string;
        welcome_statement: string;
        sustainability_snapshot: File | null;
        key_highlights: string;
        philosophy: string;
        engagement1: string;
        engagement2: string;
        awards: {award: string; award_file: File;}[];
        accolades: string;
        memberships: {membership: string; membership_details: string;}[];
        initative1: string;
        initative2: string;
        initative3: string;
        initative4: string;
    };
    awardsListCount: number;
    memershipListCount: number;
    esgPerformanceObj:{
        circular_economy_initatives: string;
        membership: string;
        resource_efficiencey: string;
        well_being: string;
        partnerships: string;
        community: string;
        diversity_efforts: string;
        certifications:{title: string; certificate: File;}[];
        ethical_policies:string[];
        data_security: string;
    };
    certificationsListCount: number;
    ethicalPoliciesListCount: number;
    unsdgList : {
        id: number;
        goal_id: number;
        goal_title: string;
        goal_name: string;
        goal_description: string;
        goal_icon: string;
        goal_enabled: boolean;
        goal_green_icon: string;
    }[];
    fillDetails: boolean;
    choosenUNSDG: {goal_id: number; id: number; goal_title:string; details: string;}[];
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ReportBuilderFormController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    account_id: string;
    company_id: string;
    tabOptions: string[] = ["General", "ESF Performance", "UN SDGs"];
    fileInputRef:any =  createRef();
    awardFileInputRef:any =  createRef();
    certificateFileInputRef:any =  createRef();
    unsdgApiCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
        const orgDetails = JSON.parse(localStorage.getItem("organization") || "{}");
        this.account_id = userDetails.meta.id;
        this.company_id = orgDetails.id;


        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            activeStep: 0,
            generalObj: {
                mission: "",
                vision: "",
                welcome_statement: "",
                sustainability_snapshot: null,
                key_highlights: "",
                philosophy: "",
                engagement1: "",
                engagement2: "",
                awards: [],
                accolades: "",
                memberships: [],
                initative1: "",
                initative2: "",
                initative3: "",
                initative4: ""
            },
            awardsListCount: 1,
            memershipListCount: 1,
            esgPerformanceObj:{
                circular_economy_initatives: "",
                membership: "",
                resource_efficiencey: "",
                well_being: "",
                partnerships: "",
                community: "",
                diversity_efforts: "",
                certifications: [],
                ethical_policies: [],
                data_security: ""
            },
            certificationsListCount: 1,
            ethicalPoliciesListCount: 1,
            unsdgList: [],
            fillDetails: false,
            choosenUNSDG: [],
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const requestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (requestCallId === this.unsdgApiCallId) {
                if (responseJson.unsdg_goals?.length > 0) {
                    const list = responseJson.unsdg_goals.filter((item:any) => item.goal_enabled).map((item:any) => {
                        return {
                            id: item.id,
                            goal_id: item.goal_id,
                            goal_title: item.goal_title,
                            details: '',
                        }
                    });
                    this.setState({ unsdgList: responseJson.unsdg_goals, choosenUNSDG: list });
                }
            }
        }
    }

    async componentDidMount() {
        this.unsdgApiCallId = await this.apiCall({
            endPoint: configJSON.unSDGEndPoint,
            apiBody: null,
            apiMethod: configJSON.exampleAPiMethod
        });
    }

    handleClick = () => {
        if (this.fileInputRef.current) {
            this.fileInputRef.current.click();
        }
    };

    handleFileChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            this.setState(prevPorps => ({
                generalObj: {
                    ...prevPorps.generalObj,
                    sustainability_snapshot: file,
                },
            }));
        }
    };

    awardUploadHandler = () => {
        if (this.awardFileInputRef.current) {
            this.awardFileInputRef.current.click();
        }
    }

    awardsFileHandler = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            const list = [...this.state.generalObj.awards];
            list[index] = {
                ...list[index],
                award_file: file,
            }
            this.setState(prevPorps => ({
                generalObj: {
                    ...prevPorps.generalObj,
                    awards: list,
                },
            }));
        }
    }

    certificateUploadHandler = () => {
        if (this.certificateFileInputRef.current) {
            this.certificateFileInputRef.current.click();
        }
    }

    certificateFileHandler = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            const list = [...this.state.esgPerformanceObj.certifications];
            list[index] = {
                ...list[index],
                certificate: file,
            }
            this.setState(prevProps => ({
                esgPerformanceObj: {
                    ...prevProps.esgPerformanceObj,
                    certifications: list,
                },
            }));
        }
    }

    apiCall = async (data: { endPoint: string; apiBody: any; apiMethod: string }) => {
        const { endPoint, apiBody, apiMethod } = data;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("authToken")
        };
        
        const body: any = { account_id: this.account_id, ...apiBody };

        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

        apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), apiMethod);

        apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

        runEngine.sendMessage(apiRequest.id, apiRequest);
        return apiRequest.messageId;
    };
    // Customizable Area End
}
