export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const closeChip = require("../assets/close.svg")
export const editIcon = require("../assets/edit.svg");
export const trashIcon = require("../assets/trash.svg")
export const successCheck = require("../assets/success_check.svg")
export const arrowDown = require("../assets/chevron-down.svg")
export const clockIcon = require("../assets/clock.svg")
export const editPencilIcon = require("../assets/Edit_duotone_line.svg")
export const checkIcon = require("../assets/checkIcon.svg")
export const blackCheckIcon = require("../assets/blackCheckIcon.svg")