export const act = require('../assets/act.png')
export const act_active = require('../assets/act_active.svg')
export const act_new = require('../assets/act_new.svg')
export const expert = require('../assets/expert.png')
export const expert_active = require('../assets/expert_active.svg')
export const expert_new = require('../assets/expert_new.svg')
export const measure = require('../assets/measure.svg')
export const measure_active = require('../assets/measure_active.svg')
export const overview = require('../assets/overview.png')
export const overview_new = require('../assets/overview_new.svg')
export const overview_active = require('../assets/overview_active.svg')
export const setting = require('../assets/setting.png')
export const setting_active = require('../assets/setting_active.svg')
export const setting_new = require('../assets/setting_new.svg')
export const start = require('../assets/start.png')
export const start_new = require('../assets/start_new.svg')
export const survey = require('../assets/survey.png')
export const transform = require('../assets/transform.png')
export const transform_new = require('../assets/transform_new.svg')
export const transform_active = require('../assets/transform_active.svg')
export const logo = require('../assets/logo.svg');
export const logoLight = require('../assets/Logo-light.png')
export const Notifications = require('../assets/Notifications.png')
export const down = require('../assets/down.png')
export const time = require('../assets/time.svg')
export const orgInfoIcon = require("../assets/org_info_icon.svg");
export const planIcon = require("../assets/plan_icon.svg");
export const usersIcon = require("../assets/users_icon.svg");
export const orgInfoIconActive = require("../assets/org_info_icon_active.svg");
export const planIconActive = require("../assets/plan_icon_active.svg");
export const usersIconActive = require("../assets/users_icon_active.svg");
export const verticalDots = require("../assets/vertical_dots.svg");
export const start_active = require("../assets/start_active.png");
export const downSelect = require("../assets/downSelect.png");
export const checkboxIcon = require("../assets/checkbox_icon.svg");
export const checkboxIconActive = require("../assets/checkbox_active_icon.svg");
export const successLogo = require("../assets/success_icon.svg");
export const overviewbg = require("../assets/overviewbg.png");
export const arrows = require("../assets/arrows.png");

export const checkGreenCircle = require("../assets/check_green_circle.svg");
export const chevron_down = require("../assets/chevron_down.svg");
export const dropdownIcon2 = require("../assets/dropdown_icon_2.svg");
export const info = require("../assets/new_info.svg");
export const empty = require("../assets/empty.png");
export const line1 =require("../assets/Vector1.png")
export const line2 =require("../assets/Vector2.png")
export const line3 =require("../assets/Vector3.png")
export const line4 =require("../assets/Vector4.png")
export const line5 =require("../assets/Vector5.png")
export const line6 =require("../assets/Vector6.png")
export const companyImg = require("../assets/companyImg.png")
export const editImg = require("../assets/editImg.png")
export const briefcaseImg = require("../assets/briefcase.svg")
export const userImg = require("../assets/user.png")
export const activeUserImg = require("../assets/activeUser.png")
export const activeBriefcaseImg = require("../assets/activeBriefcase.png")
export const uploadImg = require("../assets/UploadIcon.png")
export const EyeOff = require("../assets/eyeOff.svg")
export const checkImg = require("../assets/check.png")
export const blackCheckImg = require("../assets/blackCheck.png")
export const emmision = require("../assets/emission.png")
export const dsc_report = require("../assets/dsc_report.png")
export const org_report = require("../assets/org_report.png")
export const env_report = require("../assets/env_report.png")
export const crossImage = require("../assets/cross.svg")
export const ShowEye = require("../assets/showEye.png")
export const arrowDown = require("../assets/image_chevron-down.svg")
export const sadFace = require("../assets/sad_face.svg")
export const esgVaultIcon = require("../assets/esgVaultIcon.png")
export const esgVaultActiveIcon = require("../assets/esgVaultActiveIcon.png")





