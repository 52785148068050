import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
interface SubSubItemsAttributes1{
    sub_header_position:number
    sub_header_title:string
    sub_link_type:string | null
    sub_predefined_link:string
    sub_external_link:string
    sub_header_sub_icon:string
    page:string
}
interface HeaderSubSubItems1{
    id:string
    type:string
    attributes:SubSubItemsAttributes1
}
interface SubItemsAttributes1 {
    position:number
    title:string
    link_type:string |  null
    predefined_link:string
    external_link:string
    page:string | null
    header_sub_sub_items:{
        data:HeaderSubSubItems1[]
    }
}
interface HeaderSubItems1 {
    id:string
    type:string
    attributes:SubItemsAttributes1
}
interface HeaderAttribute1{
    position:number
    title:string
    link_type:string | null
    predefined_link:string | null
    external_link:string | null
    page:string | null
    header_sub_items:{
        data:HeaderSubItems1[]
    }
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:{[key:string]:string};
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  headerDataFromApi:{
    data:{
        id:string
        type:string
        attributes:HeaderAttribute1
    }[]
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  displayHeaderID:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      headerDataFromApi:{
        data: []
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getPathDetails();
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      
      this.handleHeaderDetails(requestCallId, message);
    }
    // Customizable Area End
  }
  handleHeaderDetails = (requestCallId: string, message: Message) => {
    if (this.displayHeaderID === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      
      if (responseJson.data) {
        localStorage.setItem("cachedHeaderData", JSON.stringify(responseJson)); // ✅ Cache response
        this.setState({ headerDataFromApi: responseJson });
      }
    }
  };
  getPathDetails = () => {

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.displayHeaderID = requestMessage.messageId;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "Cache-Control": "max-age=600, must-revalidate",
      "Accept-Encoding": "gzip, deflate, br",
      "Connection": "keep-alive",
    };

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.headerApiEndPoint}?fields=id,title,page`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };  
  // Customizable Area End
}