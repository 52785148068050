Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TaskAllocator";
exports.labelBodyText = "TaskAllocator Body";
exports.reportBuilderText = "Report Builder";
exports.reportBuilderDescription = "Streamline data, visualize insights, and deliver impactful results with our intuitive report-building tools."
exports.viewSampleReports = "View our sample reports";
exports.sampleReportsDscription = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's";
exports.createOwnReport = "Create your own report";
exports.createOwnReportDescription = "Click on the form below to generate your own report effortlessly.";
exports.reportBuilderFormText = "Report Builder Form";
exports.reportBuilderFormDescription = "Create and generate your own customized report with ease.Get started!";
exports.unSDGEndPoint = "bx_block_cfunsdgs/unsdg_goals_list";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End