import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import TeamWidget from "./landingpage/TeamWidget";
import PricingTable from "./Pricing/PricingTable.web";
import FaqsSection from "./Pricing/FaqsSection.web";
import BlogPageHeadingSectionWeb from "./BlogPageHeadingSection.web";
import OfferingSection from "./Carbonzeroed.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ContentWidget from "./ContentWidget.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import DynamicContentController, {
  Props,
  configJSON,
} from "./DynamicContentController";
import CallToActionSection from "./CallToActionSection.web";
import ContactUsPage from "./ContactUs/ContactUsPage.web";
import CarouselWidgetLight from "./landingpage/CarouselWidgetLight";
import CarouselWidget from "./landingpage/CarouselWidget";
import CallToActionLink from "./Jobs/CallToActionLink.web";
import Heading from "../../../components/src/HomePage/Heading";
import HowWeDo from "../../../components/src/HomePage/HowWeDo";
import WhyCarbonzeroed from "../../../components/src/HomePage/WhyCarbonzeroed";
import AboutUs from "../../../components/src/HomePage/AboutUs";
import WhatWeDo from "../../../components/src/HomePage/WhatWeDo";
import GetCertified from "../../../components/src/HomePage/GetCertified";
import Testimonials from "../../../components/src/HomePage/Testimonials";
import ReadyForFuture from "../../../components/src/HomePage/ReadyForFuture";
import Carbonzeroed from "../../../components/src/HomePage/Carbonzeroed";
import CallToAction from "../../../components/src/HomePage/CallToAction";
import Info from "../../../components/src/HomePage/Info";
import Specialists from "../../../components/src/AboutUs/Specialists";
import OurMission from "../../../components/src/AboutUs/OurMission";
import Potential from "../../../components/src/AboutUs/Potential";
import Journey from "../../../components/src/AboutUs/Journey";
import Value from "../../../components/src/AboutUs/Value";
import Recognition from "../../../components/src/AboutUs/Recognition";
import Leader from "../../../components/src/AboutUs/Leader";
import HeadingContent from "../../../components/src/AboutUs/HeadingContent";
import Statistics from "../../../components/src/AboutUs/Statistics";
import Career from "../../../components/src/Career";
import BodyToolkit from "../../../components/src/Toolkit/BodyToolkit";
import HeadingToolkit from "../../../components/src/Toolkit/HeadingToolkit";
import ServicePopup from "../../../components/src/HomePage/ServicePopup";


export default class DynamicContent extends DynamicContentController {
  /* method(arg0: number) {
    throw new Error("Method not implemented.");
  } */
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // This is used to fetch page data dynamically on search routes
  async componentDidMount() {
    this.fetchDynamicRoutes();
    if (window && window.location.pathname === "/contact-us") {
      this.getContactUsContent();
    }
    window && window.scrollTo(0, 0);
    this.getTopNoticeData();
  }

  // Customizable Area End
  getHeadingColors() {
    const pathName = window.location.pathname;
    const pathnameBlog = pathName?.toLowerCase();
    if (pathnameBlog === "/toolkit") {
      return {
        bgColor: "#F4DCD4",
        tagColor: "#F96C30",
        primaryButtonColor: "#FFFFFF",
        secondaryButtonBg: "#FFFFFF"
      };
    } else {
      return {
        bgColor: "#ECFBF1",
        tagColor: "#43D270",
        primaryButtonColor: "#000000",
        secondaryButtonBg: "#FFFFFF"
      }
    }
  }

  render() {
    const bannerData = this.state.topNoticeData && this.state.topNoticeData.data
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {window.location.pathname === "/" &&
            <ServicePopup bannerData={bannerData}/>
        }
        <div>
          {
            window.location.pathname === "/contact-us" ?
              <ContactUsPage pageData={this.state.contactusPageData} navigation={this.props.navigation} id={this.props.id}/> :
              this.state.landingPageData && this.state.landingPageData.length > 0 &&
              this.state.landingPageData.map((page: any, index: number) => {
                switch (page?.attributes?.static_widget_code) {
                  case "widget-1":
                    return (
                      <Heading
                        navigate={this.handleNavigation}
                        key={`Heading${index}`}
                        {...page.attributes}
                      />
                    );
                  case "widget-2":
                    return (
                      <HowWeDo
                        navigate={this.handleNavigation}
                        key={`HowWeDo${index}`}
                        {...page.attributes}
                      />
                    );
                  case "running_widget":
                    return (
                      page.attributes.static_page_code === 'home' ?
                        <AboutUs
                          navigate={this.handleNavigation}
                          key={`AboutUs${index}`}
                          {...page.attributes}
                        />
                        : <Statistics
                          key={`Statistics${index}`}
                          {...page.attributes}
                        />
                    )
                  case "widget-3":
                    return (
                      <WhyCarbonzeroed
                        navigate={this.handleNavigation}
                        key={`WhyCarbonzeroed${index}`}
                        {...page.attributes}
                      />
                    );
                  case "expertise_widget":
                    return (
                      <WhatWeDo
                        navigate={this.handleNavigation}
                        key={`WhatWeDo${index}`}
                        {...page.attributes}
                      />
                    );
                  case "framework-logo-widget":
                    return (
                      <GetCertified
                        key={`GetCertified${index}`}
                        {...page.attributes}
                      />
                    );
                  case "testimonial_widget":
                    return (
                      <Testimonials
                        key={`Testimonials${index}`}
                        {...page.attributes}
                      />
                    );
                  case "widget-4":
                    return (
                      <ReadyForFuture
                        navigate={this.handleNavigation}
                        key={`ReadyForFuture${index}`}
                        {...page.attributes}
                      />
                    );
                  case "info_widget":
                    return (
                      <Info
                        navigate={this.handleNavigation}
                        key={`Info${index}`}
                        {...page.attributes}
                      />
                    )
                  case "widget-5":
                    return (
                      <Carbonzeroed
                        navigate={this.handleNavigation}
                        key={`Carbonzeroed${index}`}
                        {...page.attributes}
                      />
                    );
                  case "green_widget":
                    return (
                      <CallToAction
                        navigate={this.handleNavigation}
                        key={`CallToAction${index}`}
                        {...page.attributes}
                      />
                    );
                  case "widget-6":
                    const {page: pageName, header, description} =
                      page.attributes;
                    const widget6Components: {
                      [key: string]: () => JSX.Element;
                    } = {
                      "About us": () => (
                        <HeadingContent
                          navigate={this.handleNavigation}
                          key={`HeadingContent${index}`}
                          {...page.attributes}
                        />
                      ),
                      Toolkit: () => (
                        <HeadingToolkit
                          key={`HeadingToolkit${index}`}
                          navigate={this.handleNavigation}
                          {...page.attributes}
                        />
                      ),
                      default: () => {
                        return (
                        <BlogPageHeadingSectionWeb
                          key={`BlogPageHeadingSectionWeb2${index}`}
                          tag={pageName}
                          heading={header}
                          description={description}
                          {...page.attributes}
                        />
                        )
                      },
                    };
                    const key = page.attributes.page;
                    const component = widget6Components[key] || widget6Components["default"];
                    return component();
                  case "widget-7":
                    return (
                      <OfferingSection
                        key={`OfferingSection${index}`}
                        {...page.attributes}
                      />
                    );
                  case "widget-8":
                    return (
                      <CallToActionSection
                        headingType={'lg'}
                        heading={page?.attributes?.header}
                        themeMode={this.getCtaMode()}
                        buttonLabel={page?.attributes?.widget_item_buttons?.data[0]?.attributes?.name}
                        buttonLink={page?.attributes?.widget_item_buttons?.data[0]?.attributes?.link}
                        inputPlaceholder={"Email Address"}
                        key={`CallToActionSection${index}`}
                        onPrivacyPolicyClick={this.navigateToPrivacyPolicyPage}
                        {...page?.attributes}
                      />
                    );
                  case "widget-9": 
                    return (
                      <PricingTable
                        key={`PricingTable${index}`}
                        showMonthYearToggle={false}
                        {...page?.attributes}
                      />
                    );
                  case "widget-10":
                    return (
                      <FaqsSection
                        key={`FaqsSection${index}`}
                        {...page?.attributes}
                      />
                    );
                  case "content-widget":
                    return (
                      <ContentWidget
                        containerType={'full-width'}
                        key={`content-widget${index}`}
                        {...page?.attributes}
                      />
                    );
                    case "team-widget":
                    return (
                      <TeamWidget
                        key={`team-widget${index}`}
                        {...page?.attributes}
                      />
                    );
                  case "widget-14":
                   
                    return (
                      <CallToActionLink
                        themeMode="dark"
                        {...page?.attributes}
                        key={`CallToActionLinkSection${index}`}
                      />
                    );
                  case "specialist_logo_widget":
                    return (
                      <Specialists
                        key={`Specialists${index}`}
                        {...page?.attributes}
                      />
                    );
                  case "mission_vision_widget":
                    return (
                      <OurMission
                        key={`OurMission${index}`}
                        {...page?.attributes}
                      />
                    )
                  case "service_widget":
                    return (
                      <Potential
                        navigate={this.handleNavigation}
                        key={`Potential${index}`}
                        {...page?.attributes}
                      />
                    )
                  case "journey_time_widget":
                    return (
                      <Journey
                        key={`Journey${index}`}
                        {...page?.attributes}
                      />
                    )
                  case "journey_card_widget":
                    return (
                      <Value
                        key={`Value${index}`}
                        {...page?.attributes}
                      />
                    )
                  case "customer_service_widget" :
                    return  (
                      <Recognition
                        key={`Recognition${index}`}
                        {...page?.attributes}
                      />
                    )
                  case "meet_leader_widget":
                    return (
                      <Leader
                        key={`Leader${index}`}
                        {...page?.attributes}
                      />
                    )
                  case "career_widget":
                    return (
                      <Career
                        viewJobDetails={this.viewJobDetails}
                        key={`Career${index}`}
                        {...page.attributes}
                      />
                    )
                  case "toolkit_widget":
                    return (
                      <BodyToolkit
                        navigate={this.goToBlogDetails}
                        key={`BodyToolkit${index}`}
                        {...page.attributes}
                      />
                    );
                  default:
                    return (
                      <div
                        data-test-id="widget not found"
                        key={`widget_key${index}`}
                      >
                      </div>
                    );
                }
              })

          }
          {
            this.state.landingPageData && this.state.landingPageData.length === 0 && <div
              style={{ color: "grey", height: "calc(100vh - 145px)", display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <CircularProgress color="inherit" />
            </div>
          }
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
