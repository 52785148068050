import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  navLinks: string[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RolesPermissionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPagesApiCallId: string = "";
  account_id: number;
  account_type: string = "";
  organizationid: number = 0;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionResponseData)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      navLinks: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.account_id = JSON.parse(localStorage.getItem("userDetails") as string)?.meta?.id
    this.account_type = JSON.parse(localStorage.getItem("userDetails") as string)?.meta?.user_account_type
    this.organizationid = JSON.parse(localStorage.getItem('organization') as string)?.id
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responceData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiCallId === this.getPagesApiCallId) {
        if (responceData.errors?.[0]?.token) {
          this.logoutUser();
          this.saveTokenAlert(responceData.errors[0].token);
          return;
        }
        this.setState({ navLinks: responceData.page_endpoints });
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    const linkExists = this.checkAccessForDynamicLinks();
    if (linkExists) {
      return;
    }
    
    if (
      this.state.navLinks?.length && 
      this.state.navLinks.length > 0 && 
      !this.state.navLinks.includes(window.location.pathname)
    ) {
      window.open("/access-restricted", "_self");
    }
  }

  checkAccessForDynamicLinks = () => {
    if (
      this.state.navLinks?.length && 
      this.state.navLinks.length > 0
    ) {
      const linkExists = this.state.navLinks.some((link) => {
        if (
          (window.location.pathname.startsWith(
            "/transform/hub/marketplace/details",
          ) ||
            window.location.pathname.startsWith(
              "/measure/log-your-esg-factor/details",
            ) ||
            window.location.pathname.startsWith(
              "/measure/climatic-goals/",
            ) || window.location.pathname.startsWith(
              "/act/action-plan/task-list/"
            )) &&
          window.location.pathname.startsWith(link)
        ) {
          return true;
        }
        return false;
      });
      
      return linkExists;
    }
  }

  handleRedirection = () => {
    const isLoggedin = JSON.parse(localStorage.getItem("isLogin") as string);

    if (!isLoggedin) {
      window.open("/login/primary", "_self");
    }
  }

  async getPages() {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    let httpBody = {
      "account_id": this.account_id,
    } as { company_id?: number };

    if(this.account_type === "portfolio_manager"){
      httpBody.company_id = this.organizationid
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPagesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pagesEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  logoutUser = () => {
    localStorage.clear();
    window.open("/login/primary", "_self");
  }

  saveTokenAlert = (message: string) => {
    const alert = {
      message,
      isOpen: true,
    }

    setStorageData("tokenAlert", JSON.stringify(alert));
  }

  // Customizable Area End
}
